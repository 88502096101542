import React from 'react';
import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { sendMail } from './crud/email';

import '../css/EmailModal.css';

const EmailModal = () => {

  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(undefined)

  const handleClose = () => {
    setShow(false)
    setMsg(undefined);
  };
  const handleShow = () => setShow(true);

  const { control, reset, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      desde: '',
      mensaje: '',
    }
  });


  const onSubmit = async (data) => {
    let getMail = {}
    getMail = {
      desde: data.desde,
      mensaje: data.mensaje
    }

    //console.log(getUser)
    const result = await sendMail(getMail)
    //console.log(result)
    if (result === null) {
      setMsg(result)
    } else {
      setMsg(result)
      reset()
    }

  };
  const compModal = () => {
    if (show) {
      document.getElementById('EmailModal').focus();
      //console.log(document.getElementById('EmailModal'))
    }
  }

  useEffect(() => {
    compModal()
  })

  /*<div className="position-relative">
  <Button className='correo' variant="primary" onClick={handleShow} className="position-absolute bottom-0 end-0 fixed-bottom">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
  </svg>
</Button>
</div>
*/
  return (
    <>

      <div className="buton-container">
        <div className="btn-float">
          <button onClick={handleShow} target="_blank" className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" className="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
            </svg>
          </button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} bg="dark" id="EmailModal">
        <Modal.Header closeButton>
          <Modal.Title>Send your email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" >
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "You have to put a valid email address",
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Form.Label htmlFor='Email address'>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoFocus
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      id="email"
                      name="email"
                    />
                  </>
                )}
                name="desde"
              />
              {errors?.desde ? console.log(errors?.desde) : <div></div>}
            </Form.Group>
            <Form.Group
              className="mb-3"
            >
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Form.Label htmlFor='message'>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Write your message here"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      id="message"
                      name="message"

                    />

                  </>
                )}
                name="mensaje"
              />
              {errors?.mensaje ? console.log(errors?.mensaje) : <div></div>}
            </Form.Group>
            <div className="buton-emailform">
              <div className="btn-emailform-float">
                <a onClick={handleSubmit(onSubmit)} target="_blank" className="btn-emailform" tabIndex="0">
                  Send e-mail
                </a>
              </div>
            </div>
          </Form>
          {msg !== undefined ? <div className='d-flex justify-content-center align-content-center'>{msg}</div> : <div></div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmailModal