import { URL_API } from "../constants";

export const sendMail = async (setUser) => {
	try {
		const res = await fetch( URL_API + "/procesaremail", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setUser)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}