import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { LogInUser } from '../crud/user.jsx';

import { KEY_AS } from "../constants";
import { compDB } from "../constants/CheckDatabse.jsx"

import "../../css/controlPanel/LogIn.css";

import HeaderPanel from './HeaderPanel';

const LogIn = ({ navigation }) => {

  const navigate = useNavigate();

  const [eyes, setEyes] = useState(require('../../assets/two-eyelashes.png'));
  const [secureText, setSecureText] = useState("password");
  const [show, setShow] = useState(false);
  const [showComp, setShowComp] = useState(false);
  const handleCloseComp = () => setShowComp(false);
  const handleShowComp = () => setShowComp(true);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      username: '',
      password: '',
    }
  });

  const secure = async () => {
    if (secureText === "password") {
      setSecureText("text")
      setEyes(require('../../assets/cartoon-eyes.png'));
    } else {
      setSecureText("password")
      setEyes(require('../../assets/two-eyelashes.png'))
    }
  }

  const onSubmit = async (data) => {
    const compData = await compDB();
    if (compData) {
      let getUser = {
        username_user: data.username,
        password_user: data.password
      };
  
      //console.log(getUser)
      const result = await LogInUser(getUser);
      //console.log(result)
      if (result === null) {
        setShow(true);
      } else {
        // Guardar en localStorage
        localStorage.setItem(KEY_AS, JSON.stringify(result));
        navigate("/admin/work");
      }
    } else {
      handleShowComp();
    }
  };

  return (
    <>
      <Modal show={showComp} onHide={handleCloseComp}>
        <Modal.Header closeButton>
          <Modal.Title>Log In error (-‿-")</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleCloseComp}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>

      <h1 className='d-flex justify-content-center align-content-center pt-2'>Control Panel</h1>
      <div className='container-sm pt-5 pb-5'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div>
                  <input
                    placeholder={errors.username ? 'Usuario obligatorio*' : 'Usuario'}
                    onBlur={onBlur}
                    onChange={onChange}
                    type='text'
                    value={value}
                  />
                </div>
              )}
              name="username"
            />

          </div>

          <div className='mt-5'>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="d-flex">
                  <input
                    placeholder={errors.password ? 'Contraseña obligatoria*' : 'Contraseña'}
                    onBlur={onBlur}
                    onChange={onChange}
                    type={secureText}
                    value={value}
                  />
                  <div className="buton-pass">
                    <div className="btn-pass-float">
                      <a onClick={secure} target="_blank" className="btn-pass">
                        <img src={eyes}></img>
                      </a>
                    </div>
                  </div>
                </div>
              )}
              name="password"
            />
          </div>
          <div>
            <div className="enter-buton">
              <div className="enter-btn-float">
                <a onClick={handleSubmit(onSubmit)} target="_blank" className="enter-btn">
                  Entrar
                </a>
              </div>
            </div>
          </div>
          {
            show &&
            <Alert className="mt-5" variant="danger" onClose={() => setShow(false)} dismissible>
              <Alert.Heading>Te equivocaste en el usuario o contraseña</Alert.Heading>
              <p>
                Corrigelo y vuelve a intentarlo :D
              </p>
            </Alert>
          }
        </form>
      </div>
    </>
  );
};


export default LogIn;