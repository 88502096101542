import { getComp } from "../crud/work"

export const compDB = async () => {
  
  const userAsync = await getComp()
  if (userAsync) {
    return true
  } else {
    return false
  }

}