import { URL_API } from "../constants";

//Text
export const getSoftSkill = async () => {
	try {
		const res = await fetch(URL_API + "/softskill", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addSoftSkill = async (setWork) => {
	try {
		console.log(JSON.stringify(setWork))
		const res = await fetch(URL_API + "/addsoftskill", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actSoftSkill = async (url, setActWork) => {
	try {
		console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/actsoftskill/" + url, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delSoftSkillText = async (url) => {
	try {
		const res = await fetch(URL_API + "/delsoftskill/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

//Image
export const getImageSoftSkill = async () => {
	try {
		const res = await fetch(URL_API + "/softskillimage", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addImageSoftSkill = async (setImage) => {
	try {
		const res = await fetch(URL_API + "/addsoftskillimage/", {
			method: "POST",
			headers: {
				/*
				Accept: "application/json",
				"Content-Type": "application/json",
				*/
			},
			body: setImage
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actImageSoftSkill = async (setActWork) => {
	try {
		const res = await fetch(URL_API + "/actsoftskillimage/", {
			method: "PUT",
			headers: {
				/*
				Accept: "application/json",
				"Content-Type": "application/json",
				*/
			},
			body: setActWork
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actDescSoftSkill = async (setActWork) => {
	try {
		const res = await fetch(URL_API + "/actsoftskilldesc/", {
			method: "PUT",
			headers: {
				
				Accept: "application/json",
				"Content-Type": "application/json",
				
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delSoftSkillImage = async (url) => {
	try {
		const res = await fetch(URL_API + "/delsoftskillimage/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}