import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Header from './Header';
import EmailModal from './EmailModal';
import Footer from './Footer';
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { sendMail } from './crud/email';

import '../css/Contact.css'

const Contact = () => {
  const [msg, setMsg] = useState(undefined)

  const { control, reset, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      desde: '',
      mensaje: '',
    }
  });

  const onSubmit = async (data) => {
    let getMail = {}
    getMail = {
      desde: data.desde,
      mensaje: data.mensaje
    }

    //console.log(getUser)
    const result = await sendMail(getMail)
    console.log(result)
    if (result === null) {
      setMsg(result)
    } else {
      setMsg(result)
      reset()
    }
  };

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }


  useEffect(() => {
    componentFocus()
  }, [location.pathname])

  return (
    <>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          <section>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" >
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "You have to put a valid email address",
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <Form.Label htmlFor='Email address'>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        autoFocus
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        id="email"
                        name="email"
                      />
                    </>
                  )}
                  name="desde"
                />
                {errors?.desde ? console.log(errors?.desde) : <div></div>}
              </Form.Group>
              <Form.Group
                className="mb-3"
              >
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <Form.Label htmlFor='message'>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={18}
                        placeholder="Write your message here"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        id="message"
                        name="message"

                      />

                    </>
                  )}
                  name="mensaje"
                />
                {errors?.mensaje ? console.log(errors?.mensaje) : <div></div>}
              </Form.Group>
              <div className="buton-contactform">
                <div className="btn-contactform-float">
                  <a onClick={handleSubmit(onSubmit)} target="_blank" className="btn-contactform" tabIndex="0">
                    Send e-mail
                  </a>
                </div>
              </div>
            </Form>
            {msg !== undefined ? <div className='d-flex justify-content-center align-content-center'>{msg}</div> : <div></div>}
          </section>
        </div >

        <section>
          <EmailModal className='correo' />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Contact