import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { Dropdown, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import $ from 'jquery';

import { useContext } from 'react';
import { FiltersContext } from './FiltersContext';

import { compDB } from "./constants/CheckDatabse.jsx"
import { getFilter, getTitleFilter } from "./crud/filter.jsx"

import '../css/Header.css';

//<img src="/img.jpg" alt="" width="50" height="50" className="img-fluid img-thumbnail" />

const Header = () => {

  const [title, setTitle] = useState('Error loading data (-‿-")')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [filter, setFilter] = useState([]);
  const [titleFilter, setTitleFilter] = useState([]);

  const chargeTitleFilter = async () => {
    const compData = await compDB()
    if (compData) {
      const titleFilterData = await getTitleFilter()
      if (titleFilterData.length > 0) {
        return setTitleFilter(titleFilterData)
      }
    } else {
      setTitle('Error loading title filter (-‿-")')
      handleShow()
    }
  }

  const chargeFilter = async () => {
    const compData = await compDB()
    if (compData) {
      const filterData = await getFilter()
      if (filterData.length > 0) {
        return setFilter(filterData)
      }
    } else {
      setTitle('Error loading filter (-‿-")')
      handleShow()
    }
  }

  const { selectedFilters, setSelectedFilters } = useContext(FiltersContext);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setSelectedFilters((prevFilters) =>
      e.target.checked ? [...prevFilters, value] : prevFilters.filter((filter) => filter !== value)
    );
  };

  useEffect(() => {
    /*
    $("Nav .nav-link").on("click", function(){
      $("Nav").find(".active").removeClass("active");
      $(this).addClass("active");
   });
    */
    chargeTitleFilter()
    chargeFilter()
    var pathname = window.location.pathname,
      pages = ['about', 'work', 'experiencies', 'softskills', 'contact'];

    $('.nav-link').each(function (i) {
      if (pathname.includes(pages[i - 1])) $(this).addClass('active');
      else if (this.className.includes('active')) $(this).removeClass('active');
    });
  }, [filter !== undefined, titleFilter !== undefined])

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar sticky='top' className='linea'>
        <Container>
          <Link className='navbar-brand nav-link' to={'/work'}>
            <img src="../Tom_Logo_FN-02.png" style={{ height: "4em", width: "4em" }} />
          </Link>
          <Nav className="nav w-100">
            <Link className="nav-link" to={'/about'}>About</Link>
            <Link className="nav-link" to={'/work'}>Work</Link>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className='dropdown-button' />

              <Dropdown.Menu id="dropdown-menu">
                {titleFilter !== undefined &&
                  titleFilter.map((dataTitleFilter) => (
                    <div key={dataTitleFilter.id_titlefilter}>
                      {/* Checkbox para seleccionar todos los filtros de un título */}
                      <Form.Check
                        type="checkbox"
                        label={`${dataTitleFilter.title_titlefilter}:`}
                        checked={filter.filter(a => a.titleFilter_id_filter === dataTitleFilter.id_titlefilter).every(data => selectedFilters.includes(data.work_filter))}
                        onChange={(e) => {
                          if (e.target.checked) {
                            // Selecciona todos los filtros de este título
                            const allFiltersForTitle = filter.filter(a => a.titleFilter_id_filter === dataTitleFilter.id_titlefilter)
                              .map(data => data.work_filter);

                            // Evita la duplicación asegurando que no se añadan filtros ya seleccionados
                            setSelectedFilters(prevFilters => [
                              ...new Set([...prevFilters, ...allFiltersForTitle])
                            ]);
                          } else {
                            // Desmarca todos los filtros de este título
                            const filtersToRemove = filter.filter(a => a.titleFilter_id_filter === dataTitleFilter.id_titlefilter)
                              .map(data => data.work_filter);
                            setSelectedFilters(prevFilters => prevFilters.filter(filter => !filtersToRemove.includes(filter)));
                          }
                        }}
                      />

                      {/* Filtros individuales dentro del título */}
                      {filter.filter(a => a.titleFilter_id_filter === dataTitleFilter.id_titlefilter).map((data) => (
                        <Form.Check
                          style={{ marginLeft: "1.4em" }}
                          key={data.id_filter}
                          type="checkbox"
                          id={data.id_filter}
                          label={data.work_filter}
                          value={data.work_filter}
                          checked={selectedFilters.includes(data.work_filter)} // Controla si el checkbox está marcado
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedFilters([...selectedFilters, data.work_filter]);
                            } else {
                              setSelectedFilters(selectedFilters.filter((name) => name !== data.work_filter));
                            }
                          }}
                        />
                      ))}
                    </div>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            <Link className="nav-link" to={'/experiencies'}>Experiencies</Link>
            <Link className="nav-link" to={'/softskills'}>Soft Skills</Link>
            <Link className="nav-link" to={'/contact'}>Contact</Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}
export default Header