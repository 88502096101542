import React, { useState, useEffect } from 'react'
import HeaderPanel from './HeaderPanel'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form";
import ReactPlayer from "react-player"
import Files from 'react-files'
//import axios from 'axios';

import { KEY_AS, URL_API } from "../constants"

import { getUser } from "../crud/user"
import { compDB } from "../constants/CheckDatabse.jsx"
import { getVideo, addVideo, actVideo, actDescVideo, delVideoText, getImageVideo, addImageVideo, actImageVideo, delVideoImage } from "../crud/video"
import { deleteImage } from '../crud/work';

const VideoPanel = () => {
  const [name, setName] = useState([]);
  const [imageVideo, setImageVideo] = useState([])
  const [user, setUser] = useState(null)
  const [form, setForm] = useState(false);
  const [actForm, setActForm] = useState(false);
  const [imageForm, setImageForm] = useState(false);
  const [actImageForm, setActImageForm] = useState(false);
  const [show, setShow] = useState(false);
  const [delVideo, setDelVideo] = useState(false)
  const [delImageVideo, setDelImageVideo] = useState(false)
  const [id, setId] = useState(undefined)
  const [file, setFile] = useState([])
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const [title, setTitle] = useState('Error loading data (-‿-")')
  //const [count, setCount] = useState(1)

  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCloseVideo = () => setDelVideo(false);

  const handleFormShow = () => setForm(true);
  const handleCloseForm = () => {
    reset()
    setForm(false)
  }

  const handleFormActShow = (id_video) => {
    let w = name.filter(a =>
      a.id_video === id_video
    )
    setFile({ "name": w[0].name_video.slice(12) })
    setValue("name", w[0].name_video.slice(12))
    setValue("description", w[0].description_video)
    setId(id_video)
    setActForm(true);
  }

  const handleCloseActForm = () => {
    setActForm(false);
    reset()
    //console.log(experiencie)
  }

  const handleVideoShow = (id_video, video) => {
    setDelVideo(true);
    setId([id_video, video])
    //deleteExperiencieFile(id_experiencie)
  }

  const handleChange = (newFiles) => {
    //console.log(newFiles)
    setFile(...newFiles)
  }

  const handleImageFormShow = () => setImageForm(true);
  const handleCloseImageForm = () => {
    setImageForm(false)
    reset()
  }

  const handleImageFormActShow = (id_image_video) => {
    let w = imageVideo.filter(a =>
      a.id_image_video === id_image_video
    )
    setFile({ "name": w[0].image_video.slice(12) })
    setValue("image", w[0].image_video.slice(12))
    setId(w[0].id_image_video)
    setActImageForm(true);
  }

  const handleImageCloseActForm = () => {
    setActImageForm(false);
    reset()
  }

  const handleCloseImageVideo = () => setDelImageVideo(false);
  const handleImageVideoShow = (id_video, img) => {
    setDelImageVideo(true);
    setId([id_video, img])
    //deleteExperiencieFile(id_experiencie)
  }

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  const CompleteForm = async (data) => {
    const compData = await compDB()
    if (compData) {
      setUploading(true);
      const CHUNK_SIZE = 5 * 1024 * 1024; // Tamaño de cada chunk (5MB)
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      let uploadedChunks = 0;

      for (let start = 0; start < file.size; start += CHUNK_SIZE) {
        const chunk = file.slice(start, start + CHUNK_SIZE);
        const formData = new FormData();
        formData.append("chunk", chunk);
        formData.append("filename", file.name);
        formData.append("chunkIndex", Math.floor(start / CHUNK_SIZE));

        try {
          /*
          await axios.post('http://localhost:3300/upload-chunk', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          */
          await fetch(URL_API + '/upload-chunk', {
            method: "POST",
            headers: {
              Accept: "multipart/form-data",
              //"Content-Type": "multipart/form-data",
            },
            body: formData
          });
          uploadedChunks++;
          const percentCompleted = Math.round((uploadedChunks / totalChunks) * 100);
          setProgress(percentCompleted);

        } catch (error) {
          console.error("Error al subir chunk:", error);
          setUploading(false);
          return;
        }
      }

      // Llamar a la ruta para combinar chunks
      const combineChunks = async (filename, totalChunks) => {
        try {
          /*
          const response = await axios.post('http://localhost:3300/combine-chunks', {
            filename,
            totalChunks,
          });
          */
          let date = Date.now()
          let array = {
            filename: filename,
            totalChunks: totalChunks,
            date: date
          }
          const response = await fetch(URL_API + '/combine-chunks', {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(array)
          });
          //console.log('Archivo combinado:', response);
          // Aquí puedes hacer otra petición para registrar el archivo en la base de datos si es necesario
          let arrayA = {
            name_video: date + "-" + file.name,
            description_video: data.description,
            user_id_user: user.id_user
          }
          const dataActImgWork = await addVideo(arrayA)
          if (dataActImgWork !== null || dataActImgWork !== undefined) {
            handleCloseForm()
            chargeVideo()
          }
        } catch (error) {
          console.error('Error al combinar chunks:', error);
        }
      };
      await combineChunks(file.name, totalChunks);
      setUploading(false);
      //console.log('Archivo subido por partes correctamente');
    } else {
      handleCloseForm()
      setTitle('Error uploading video (-‿-")')
      handleShow()
    }
  }

  const ActForm = async (data) => {
    const compData = await compDB()
    if (compData) {
      const y = name.filter(a =>
        a.id_video === id && a.name_video.slice(12) !== file.name
      )
      const z = name.filter(a =>
        a.id_video === id && a.description_video !== data.description
      )
      //console.log(y)
      if (y.length > 0) {
        setUploading(true);
        const CHUNK_SIZE = 5 * 1024 * 1024; // Tamaño de cada chunk (5MB)
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let uploadedChunks = 0;

        for (let start = 0; start < file.size; start += CHUNK_SIZE) {
          const chunk = file.slice(start, start + CHUNK_SIZE);
          const formData = new FormData();
          formData.append("chunk", chunk);
          formData.append("filename", file.name);
          formData.append("chunkIndex", Math.floor(start / CHUNK_SIZE));

          try {
            /*
            await axios.post('http://localhost:3300/upload-chunk', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            */
            await fetch(URL_API + '/upload-chunk', {
              method: "POST",
              headers: {
                Accept: "multipart/form-data",
                //"Content-Type": "multipart/form-data",
              },
              body: formData
            });
            uploadedChunks++;
            const percentCompleted = Math.round((uploadedChunks / totalChunks) * 100);
            setProgress(percentCompleted);

          } catch (error) {
            console.error("Error al subir chunk:", error);
            setUploading(false);
            return;
          }
        }

        // Llamar a la ruta para combinar chunks
        const combineChunks = async (filename, totalChunks) => {
          try {
            /*
            const response = await axios.post('http://localhost:3300/combine-chunks', {
              filename,
              totalChunks,
            });
            */
            let date = Date.now()
            let array = {
              filename: filename,
              totalChunks: totalChunks,
              date: date
            }
            const response = await fetch(URL_API + '/combine-chunks', {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(array)
            });
            //console.log('Archivo combinado:', response);
            // Aquí puedes hacer otra petición para registrar el archivo en la base de datos si es necesario
            let arrayA = {
              id_video: id,
              name_video: date + "-" + file.name,
            }
            const dataActImgWork = await actVideo(arrayA)
            if (dataActImgWork !== null || dataActImgWork !== undefined) {
              handleCloseActForm()
              setId(undefined)
              chargeVideo()
            }
          } catch (error) {
            //console.error('Error al combinar chunks:', error);
          }
        };
        await combineChunks(file.name, totalChunks);
        setUploading(false);
        //console.log('Archivo subido por partes correctamente');

      } else {
        handleCloseActForm()
        setId(undefined)
      }

      if (z.length > 0) {
        let arrayA = {
          id_video: id,
          description_video: data.description,
        }
        const dataActImgWork = await actDescVideo(arrayA)
        if (dataActImgWork !== null || dataActImgWork !== undefined) {
          handleCloseActForm()
          setId(undefined)
          chargeVideo()
        } else {
          handleCloseActForm()
          setId(undefined)
        }
      }
    } else {
      handleCloseActForm()
      setTitle('Error updating video (-‿-")')
      handleShow()
    }
  }

  const deleteVideo = async (id_video) => {
    //console.log(id_video)
    handleCloseVideo()
    const compData = await compDB()
    if (compData) {
      await deleteImage(id_video[1].slice(12))
      const dataDeleteVideo = await delVideoText(id_video[0])
      //console.log(dataDeleteVideo)

      setId(undefined)
      return chargeVideo()
      //return chargeExperiencie()
    } else {
      setTitle('Error deleting video (-‿-")')
      handleShow()
    }
  }

  const deleteImageVideo = async (id_image_video) => {
    handleCloseImageVideo()
    const compData = await compDB()
    if (compData) {
      await deleteImage(id_image_video[1].slice(12))
      const dataDeleteVideo = await delVideoImage(id_image_video[0])

      setId(undefined)
      return chargeImageVideo()
      //return chargeExperiencie()
    } else {
      setTitle('Error deleting thumbnail (-‿-")')
      handleShow()
    }
  }

  const CompleteImageForm = async (data) => {
    handleCloseImageForm()
    const compData = await compDB()
    if (compData) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('user_id_user', user.id_user);
      const resultImg = await addImageVideo(formData)
      if (resultImg !== null || resultImg !== undefined) {
        chargeImageVideo()
      }
    } else {
      setTitle('Error uploading thumbnail (-‿-")')
      handleShow()
    }

  }

  const ActImageForm = async (data) => {
    if (id !== "100000") {
      handleImageCloseActForm()
      const compData = await compDB()
      if (compData) {
        let ArrayVideo = [{}]
        ArrayVideo = [{
          id_image_video: id,
          image: file
        }]
        const y = imageVideo.filter(a =>
          a.id_image_video === ArrayVideo[0].id_image_video && a.image_video.slice(12) !== ArrayVideo[0].image.name
        )
        if (y.length > 0) {
          const formData = new FormData();
          formData.append('image', ArrayVideo[0].image);
          formData.append('id_image_video', ArrayVideo[0].id_image_video);
          const dataActImgWork = await actImageVideo(formData)
          //|| dataActImgWork !== null || dataActImgWork !== undefined

          //if (dataActImgWork) {
          if (dataActImgWork.affectedRows === 1) {
            chargeImageVideo()
          }
        }
        //}
      } else {
        setTitle('Error updating thumbnail (-‿-")')
        handleShow()
      }
    }

  }

  const chargeVideo = async () => {
    const compData = await compDB()
    if (compData) {
      let videoData = await getVideo()
      //console.log(videoData)
      if (videoData.length > 0) {
        videoData.forEach(element => {
          element.name_video = "../archives/" + element.name_video
        });
        return setName(videoData)
      } else {
        return setName([
          { id_video: "100000", name_video: "../archives/default.mp4" }
        ])
      }
    } else {
      setTitle('Error loading video (-‿-")')
      handleShow()
    }
  }

  const chargeImageVideo = async () => {
    const compData = await compDB()
    if (compData) {
      const imageVideoData = await getImageVideo()
      if (imageVideoData.length > 0) {
        imageVideoData.forEach(element => {
          element.image_video = "../archives/" + element.image_video
        });
        return setImageVideo(imageVideoData)
      } else {
        return setImageVideo([
          { id_video: "100000", image_video: "../archives/no_image.png" }
        ])
      }
    } else {
      setTitle('Error loading thumbnail (-‿-")')
      handleShow()
    }

  }

  const chargeUser = async () => {
    const userLocal = localStorage.getItem(KEY_AS); // Cambiar AsyncStorage por localStorage
    if (!userLocal) {
      return navigate("/admin/login");
    }
    const compData = await compDB();
    if (compData) {
      const userLocalJson = JSON.parse(userLocal); // Parsear el dato guardado en localStorage
      const userData = await getUser(userLocalJson.id_user);
      if (userData.length > 0) {
        return setUser(userData[0]);
      } else {
        navigate("/admin/login");
      }
    } else {
      setTitle('Error loading user (-‿-")');
      handleShow();
    }
  };

  useEffect(() => {
    chargeUser()
    chargeImageVideo()
    chargeVideo()
  }, [user !== undefined, name !== undefined])

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      image: "",
      description: "",
    }
  });

  const ContinueModal = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const VideoModal = () => {
    return (
      <Modal show={delVideo} onHide={handleCloseVideo}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this video?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteVideo(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseVideo}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const FormModal = () => {
    return (
      <Modal show={form} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['video/*']}
                  maxFileSize={1000000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Description</span>
                    <input
                      placeholder='Description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required description*</div> : <div></div>}
            </div>
            {uploading ? (
              <div>
                <p>Upload progress: {progress}%</p>
                <progress value={progress} max="100"></progress>
              </div>
            ) : (<input type="submit" value="Upload video" style={{ marginTop: "2em" }} />)}

          </form>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ActFormModal = () => {
    return (
      <Modal show={actForm} onHide={handleCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['video/*']}
                  maxFileSize={1000000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Description</span>
                    <input
                      placeholder='Description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required description*</div> : <div></div>}
            </div>
            {uploading ? (
              <div>
                <p>Upload progress: {progress}%</p>
                <progress value={progress} max="100"></progress>
              </div>
            ) : (<input type="submit" value="Upload video" style={{ marginTop: "2em" }} />)}

          </form>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ImageFormModal = () => {
    return (
      <Modal show={imageForm} onHide={handleCloseImageForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteImageForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <input type="submit" value="Upload image" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseImageForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ImageFormActModal = () => {
    return (
      <Modal show={actImageForm} onHide={handleImageCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Completa los datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActImageForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <input type="submit" value="Upload image" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleImageCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

    )
  }

  const ImageVideoModal = () => {
    return (
      <Modal show={delImageVideo} onHide={handleCloseImageVideo}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this image?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteImageVideo(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseImageVideo}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <>
      <HeaderPanel />
      <ContinueModal />
      <VideoModal />
      <FormModal />
      <ActFormModal />
      <ImageFormModal />
      <ImageFormActModal />
      <ImageVideoModal />
      <div className='container-sm pt-5 pb-5'>
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleFormShow} target="_blank" className="btn-form">
              Upload new video
            </a>
          </div>
        </div>
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleImageFormShow} target="_blank" className="btn-form">
              Upload new thumbnail
            </a>
          </div>
        </div>
        <h1 style={{ background: "grey", marginTop: "2em" }}>PREVIEW:</h1>
        <h4 style={{ background: "grey", marginTop: "2em" }}>Edit thumbnail</h4>
        <div className='d-flex'>

          <div className="buton-delWork">
            <div className="btn-delWork-float">
              <a onClick={() => handleImageVideoShow(imageVideo[0]?.id_image_video, imageVideo[0]?.image_video)} target="_blank" className="btn-delWork">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="buton-editWork">
            <div className="btn-editWork-float">
              <a onClick={() => handleImageFormActShow(imageVideo[0]?.id_image_video)} target="_blank" className="btn-editWork">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        {name !== undefined &&
          name.map((data) => (
            <>
              <h4 style={{ background: "grey", marginTop: "2em" }}>Edit video</h4>
              <div className='d-flex' style={{ marginBottom: "2em" }}>
                <div className="buton-delWork">
                  <div className="btn-delWork-float">
                    <a onClick={() => handleVideoShow(data.id_video, data.name_video)} target="_blank" className="btn-delWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="buton-editWork">
                  <div className="btn-editWork-float">
                    <a onClick={() => handleFormActShow(data.id_video)} target="_blank" className="btn-editWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center align-content-center'>
                <ReactPlayer
                  key={data.id_video}
                  url={data.name_video}
                  width='100%'
                  height='100%'
                  controls={true}
                  loop
                  playing={true}
                  light={imageVideo[0]?.image_video}
                />
              </div>
              <p className='d-flex justify-content-center align-content-center'>
                {"Description for blind: " + data.description_video}
              </p>
            </>
          ))
        }
      </div>
    </>
  )
}
export default VideoPanel