import React from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';

import Work from './components/Work';
import OneEnterprise from './components/OneEnterprise';
import About from './components/About';
import Experiencies from './components/Experiencies';
import SoftSkills from './components/SoftSkills';
import Contact from './components/Contact';
import Redirect from './components/Redirect';
import { FiltersProvider } from './components/FiltersContext';

import LogIn from './components/controlPanel/LogIn';
import WorkPanel from './components/controlPanel/WorkPanel';
import AboutPanel from './components/controlPanel/AboutPanel';
import ExperienciesPanel from './components/controlPanel/ExperienciesPanel';
import SoftSkillsPanel from './components/controlPanel/SoftSkillsPanel';
import VideoPanel from './components/controlPanel/VideoPanel';
import FilterPanel from './components/controlPanel/FilterPanel';

export default function App() {

  return (
    <div id="principal">
      <FiltersProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Redirect />}></Route>
            <Route path="/work" element={<Work />}></Route>
            <Route path="/enterprise/:id" element={<OneEnterprise />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/experiencies" element={<Experiencies />}></Route>
            <Route path="/softskills" element={<SoftSkills />}></Route>
            <Route path="/contact" element={<Contact />}></Route>

            <Route path="/admin/login" element={<LogIn />}></Route>
            <Route path="/admin/work" element={<WorkPanel />}></Route>
            <Route path="/admin/filter" element={<FilterPanel />}></Route>
            <Route path="/admin/about" element={<AboutPanel />}></Route>
            <Route path="/admin/experiencies" element={<ExperienciesPanel />}></Route>
            <Route path="/admin/softskills" element={<SoftSkillsPanel />}></Route>
            <Route path="/admin/video" element={<VideoPanel />}></Route>
          </Routes>
        </Router>
      </FiltersProvider>

    </div>
  );
}

