import React, { useState, useEffect } from 'react';
import Header from './Header';
import EmailModal from './EmailModal';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import '../css/SoftSkill.css'
import { compDB } from "./constants/CheckDatabse.jsx"
import { getSoftSkill, getImageSoftSkill } from './crud/softSkill';

//import * as fs from 'node:fs';
//const fs = require('fs')
//import listReactFiles from 'list-react-files'

const SoftSkills = () => {
  const [text, setText] = useState([]);
  const [imageSoftSkill, setImageSoftSkill] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [title, setTitle] = useState('Error loading data (-‿-")')

  const navigate = useNavigate();

  const chargeSoftSkill = async () => {
    const compData = await compDB()
    if (compData === true) {
      const softSkillData = await getSoftSkill()
      if (softSkillData.length > 0) {
        return setText(softSkillData)
      } else {
        return setText([
          { id_softSkill: "100000", text_softSkill: "No more texts available" }
        ])
      }
    } else {
      setTitle('Error loading text (-‿-")')
      handleShow()
    }
  }

  const chargeImageSoftSkill = async () => {
    const compData = await compDB()
    if (compData === true) {
      const aboutData = await getImageSoftSkill()
      if (aboutData.length > 0) {
        aboutData.forEach(element => {
          element.image_SoftSkill = "../archives/" + element.image_SoftSkill
        });
        return setImageSoftSkill(aboutData)
      } else {
        return setImageSoftSkill([
          { id_image_SoftSkill: "100000", image_SoftSkill: "../archives/no_image.png" }
        ])
      }
    } else {
      setTitle('Error loading image (-‿-")')
      handleShow()
    }

  }

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }

  useEffect(() => {
    chargeSoftSkill()
    chargeImageSoftSkill()
    componentFocus()
  }, [text !== undefined && location.pathname])

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          <section>
            {imageSoftSkill !== undefined &&
              imageSoftSkill.map((dataImage) => (
                <img
                  key={dataImage.id_image_SoftSkill}
                  src={dataImage.image_SoftSkill}
                  style={{ height: '25em', width: '100%', marginTop: "1em", marginBottom: "1em" }}
                  alt={dataImage.description_SoftSkill}
                />
              ))
            }
          </section>
          <section>
            {text !== undefined &&
              text.map((data) => (
                <p className="text" key={data.id_softSkill}>{data.text_softSkill}</p>
              ))
            }
          </section>
        </div>
        <section>
          <EmailModal className='correo' />
        </section>
      </main>
      <Footer />
    </>
  )
}
export default SoftSkills