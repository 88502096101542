import { URL_API } from "../constants";

//filter
export const getFilter = async () => {
	try {
		const res = await fetch(URL_API + "/filter", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addFilter = async (setWork) => {
	try {
		console.log(JSON.stringify(setWork))
		const res = await fetch(URL_API + "/addfilter", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actFilter = async (url, setActWork) => {
	try {
		console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/actfilter/" + url, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delFilter = async (url) => {
	try {
		const res = await fetch(URL_API + "/delfilter/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

//title filter
export const getTitleFilter = async () => {
	try {
		const res = await fetch(URL_API + "/titlefilter", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addTitleFilter = async (setWork) => {
	try {
		console.log(JSON.stringify(setWork))
		const res = await fetch(URL_API + "/addtitlefilter", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actTitleFilter = async (url, setActWork) => {
	try {
		console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/acttitlefilter/" + url, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delTitleFilter = async (url) => {
	try {
		const res = await fetch(URL_API + "/deltitlefilter/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

//for work
export const getFilterWork = async () => {
	try {
		const res = await fetch(URL_API + "/filterwork", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const getFilteringWork = async () => {
	try {
		const res = await fetch(URL_API + "/filteringwork", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};