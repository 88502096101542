import React, { useState, useEffect, useCallback } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import Files from 'react-files'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { KEY_AS } from "../constants"

import { getUser } from "../crud/user"

//import * as fs from 'node:fs';
//const fs = require('fs')
//import listReactFiles from 'list-react-files'

import '../../css/controlPanel/WorkPanel.css';

import { compDB } from "../constants/CheckDatabse.jsx"
import { getWork, addWork, addImageWork, actWork, actImageWork, deleteWork, getWorkFilter, addWorkFilter, actWorkFilter, deleteImage } from '../crud/work';
import { getExperiencieWork } from '../crud/experiencies.jsx'
import { getFilter, getFilterWork, getTitleFilter } from '../crud/filter.jsx'

import HeaderPanel from './HeaderPanel';

const WorkPanel = () => {
  /*
  {files.length > 0 && (
            <div className="files-gallery">
              {files.map(file => (
                <img
                  key={file.id}
                  className="files-gallery-item"
                  src={file.preview.url}
                />
              ))}
            </div>
          )}
    */

  const [user, setUser] = useState(null)
  //const [files, setFiles] = useState([])
  const [file, setFile] = useState([])
  const [show, setShow] = useState(false);
  const [form, setForm] = useState(false);
  const [actForm, setActForm] = useState(false);
  const [formWork, setFormWork] = useState([]);
  const [work, setWork] = useState([]);
  const [newWork, setNewWork] = useState([]);
  const [count, setCount] = useState(1)
  const [fail, setFail] = useState(false)
  const [delWork, setDelWork] = useState(false)
  const [prev, setPrev] = useState(false)
  const [allPrev, setAllPrev] = useState(false)

  const [enterprise, setEnterprise] = useState([])
  const [titleFilter, setTitleFilter] = useState([])
  const [filter, setFilter] = useState([])
  const [filterWork, setFilterWork] = useState([])

  const [title, setTitle] = useState('Error loading data (-‿-")')

  const [id, setId] = useState(undefined)

  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleClosePrev = () => setPrev(false);

  const handleFormShow = () => {
    chargeExperiencieWork()
    chargeTitleFilterWork()
    chargeFilter()
    setForm(true)
  }
  const handleCloseForm = () => {
    setFile([])
    reset()
    setForm(false)
  }

  const handleFormActShow = (id_work) => {
    chargeExperiencieWork()
    chargeTitleFilterWork()
    chargeFilter()
    let w = work.filter(a =>
      a.id_work === id_work
    )
    if (w.length > 0) {
      let y = filterWork.filter(a => a.work_id_work === w[0].id_work)
      let ArrayY = y[0].id_filter.split(", ")
      setFormWork([w])
      setFile({ "name": w[0].image_work.slice(12) })
      setValue("image", w[0].image_work.slice(12))
      setValue("experiencie", w[0].experiencie_id_experiencie)
      setValue("filter", ArrayY)
      setValue("description", w[0].description_work)
    }
    setActForm(true);
  }
  const handleCloseActForm = () => {
    setActForm(false);
    setFormWork([]);
    setFile([])
    reset()
    //console.log(work)
  }

  const handleCloseAllPrev = () => setAllPrev(false);

  //let a = new Array()

  const handleChange = (newFiles) => {
    setFile(...newFiles)
  }

  const ActForm = async (data) => {
    handleCloseActForm()
    const compData = await compDB()
    if (compData) {
      //console.log(data)
      //console.log(formWork[0][0].id_work)
      //formData.append(file.id, new Blob([file], { type: file.type }), file.name || 'file')
      let ArrayWork = {}
      ArrayWork = {
        id_work: formWork[0][0].id_work,
        image: file,
        experiencie_id_experiencie: data.experiencie,
        //filter_id_filter: data.filter,
        description_work: data.description
      }

      let ArrayFilterWork = {}
      ArrayFilterWork = {
        filter_id_filter: data.filter,
        work_id_work: formWork[0][0].id_work,
      }
      const dataActWork = await actWork(ArrayWork)
      if (dataActWork.affectedRows === 1) {
        //setWork([])
        chargeWork()
      }

      const dataActFilters = await actWorkFilter(ArrayFilterWork.work_id_work)
      if (dataActFilters) {
        ArrayFilterWork.filter_id_filter.forEach(async (a) => {
          let ArrayFilter = {
            filter_id_filter: a,
            work_id_work: ArrayFilterWork.work_id_work
          }
          //console.log(ArrayFilter)
          await addWorkFilter(ArrayFilter)
        })
      }

      const y = work.filter(a =>
        a.id_work === ArrayWork.id_work && a.image_work.slice(12) !== ArrayWork.image.name
      )
      //console.log(y)
      if (y.length > 0) {
        const formData = new FormData();
        formData.append('image', ArrayWork.image);
        formData.append('id_work', ArrayWork.id_work);
        const dataActImgWork = await actImageWork(formData)

        if (dataActImgWork.affectedRows === 1) {
          chargeWork()
        }
      }


    } else {
      setTitle('Error updating work (-‿-")')
      handleShow()
    }
  }

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  const onSubmit = async (data) => {
    handleCloseForm()
    const compData = await compDB()
    if (compData) {
      let ArrayWork = {}
      ArrayWork = {
        image: file,
        experiencie_id_experiencie: data.experiencie,
        filter_id_filter: data.filter,
        description_work: data.description
      }
      //console.log(result)
      //handleShow()
      //console.log(dataWork)
      const formData = new FormData();
      //formData.append(dataWork.image.id, new Blob([dataWork.image], { type: dataWork.image.type }), dataWork.image.name || 'file')
      //formData.append(dataWork.image.id, new Blob([dataWork.image], { type: dataWork.image.type }), dataWork.image.name || 'image')
      formData.append('image', ArrayWork.image);
      formData.append('description_work', ArrayWork.description_work);
      formData.append('experiencie_id_experiencie', ArrayWork.experiencie_id_experiencie);
      //formData.append('filter_id_filter', dataWork.filter_id_filter);
      //formData.append('work_id_work', result.insertId);
      //console.log(formData)
      const resultImg = await addImageWork(formData)
      if (resultImg !== null || resultImg !== undefined) {
        ArrayWork.filter_id_filter.forEach(async (a) => {
          let ArrayFilter = {
            filter_id_filter: a,
            work_id_work: resultImg.insertId
          }
          await addWorkFilter(ArrayFilter)
        })
        setNewWork([])
        chargeWork()
      }
      //console.log(resultImg)
    } else {
      setTitle('Error uploading work (-‿-")')
      handleShow()
    }
  }

  const handleWorkShow = (id_work, img) => {
    setDelWork(true);
    setId([id_work, img])
    //deleteWorkFile(id_work)
  }
  const handleCloseWork = () => setDelWork(false);

  const handlePrevShow = (id_work) => {
    setPrev(true);
    setId(id_work)
  }

  const deleteWorkFile = async (id_work) => {
    handleCloseWork()
    const compData = await compDB()
    if (compData) {
      setWork(
        work.filter(a =>
          a.id_work !== id_work
        )
      )

      await deleteImage(id_work[1].slice(12))
      const dataDeleteWork = await deleteWork(id_work[0])
      //console.log(dataDeleteWork)
      setId(undefined)
      //return chargeWork()
    } else {
      setId(undefined)
      setTitle('Error deleting work (-‿-")')
      handleShow()
    }
  }

  const deletePrevFile = (id_work) => {
    setNewWork(
      newWork.filter(a =>
        a.id_work !== id_work
      )
    )
    handleClosePrev()
    setId(undefined)
  }

  const deleteAll = () => {
    setNewWork([])
    handleCloseAllPrev();

  }

  const chargeExperiencieWork = async () => {
    const compData = await compDB()
    if (compData) {
      let workData = await getExperiencieWork()
      if (workData.length > 0) {
        //console.log(workData)
        return setEnterprise(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const chargeTitleFilterWork = async () => {
    const compData = await compDB()
    if (compData) {
      let workData = await getTitleFilter()
      if (workData.length > 0) {
        //console.log(workData)
        return setTitleFilter(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const chargeFilter = async () => {
    const compData = await compDB()
    if (compData) {
      let workData = await getFilter()
      if (workData.length > 0) {
        //console.log(workData)
        return setFilter(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const chargeFilterWork = async () => {
    const compData = await compDB()
    if (compData) {
      let workData = await getFilterWork()
      if (workData.length > 0) {
        //console.log(workData)
        return setFilterWork(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const chargeWork = async () => {
    const compData = await compDB()
    if (compData) {
      let workData = await getWork()
      if (workData.length > 0) {
        workData.forEach(element => {
          element.image_work = "../archives/" + element.image_work
        });
        return setWork(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const chargeUser = async () => {
    const userLocal = localStorage.getItem(KEY_AS); // Cambiar AsyncStorage por localStorage
    if (!userLocal) {
      return navigate("/admin/login");
    }
    const compData = await compDB();
    if (compData) {
      const userLocalJson = JSON.parse(userLocal); // Parsear el dato guardado en localStorage
      const userData = await getUser(userLocalJson.id_user);
      if (userData.length > 0) {
        return setUser(userData[0]);
      } else {
        navigate("/admin/login");
      }
    } else {
      setTitle('Error loading user (-‿-")');
      handleShow();
    }
  };

  useEffect(() => {
    chargeUser()
    chargeWork()
    chargeExperiencieWork()
    chargeFilterWork()
  }, [user !== undefined])


  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      image: "",
      experiencie: "",
      filter: [],
      description: ""
    }
  });

  const arrayWorkItems = () =>
    work.length > 0 && (
      work.map((data) => (
        enterprise.filter(a => a.id_experiencie === data.experiencie_id_experiencie).map((dataEnterprise) => (
          filterWork.filter(a => a.work_id_work === data.id_work).map((dataFilter) => (
            <Col key={data.id_work}>
              <Card className='cardPanelWork img-fluid'>
                <Card.Header>
                  <div className='d-flex'>
                    <div className="buton-delWork">
                      <div className="btn-delWork-float">
                        <a onClick={() => handleWorkShow(data.id_work, data.image_work)} target="_blank" className="btn-delWork">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="buton-editWork">
                      <div className="btn-editWork-float">
                        <a onClick={() => handleFormActShow(data.id_work)} target="_blank" className="btn-editWork">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                {data.image_work !== undefined ?
                  <Card.Img variant='top' src={data.image_work} className='fotosPanelWork img-fluid' />
                  :
                  <div></div>
                }

                <h3 style={{ color: "black" }}>Job data: </h3>
                <div className='textWork'>Experiencie: {dataEnterprise.enterprise_experiencie}</div>
                <div className='textWork'>Filters: {dataFilter.filter_names}</div>
                <div className='textWork'>Description for blind: {data.description_work}</div>
              </Card>
            </Col>
          ))
        ))
      ))
    );

  const ContinueModal = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const FailModal = () => {
    return (
      <Modal show={fail} onHide={() => navigate("/admin/work")}>
        <Modal.Header closeButton>
          <Modal.Title>Ha fallado la subida, lo siento, vuelve a intentarlo</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => navigate("/admin/work")}>
            {"Vale :("}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const WorkModal = () => {
    return (
      <Modal show={delWork} onHide={handleCloseWork}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this work?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteWorkFile(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseWork}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const PrevModal = () => {
    return (
      <Modal show={prev} onHide={handleClosePrev}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this work?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deletePrevFile(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClosePrev}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }


  const FormModal = () => {
    return (
      <Modal show={form} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>

            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image description</span>
                    <input
                      placeholder='Image description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required image description*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <select onChange={onChange} onBlur={onBlur} value={value} required>
                    <option value="">-- Select an enterprise --</option>
                    {enterprise.map(data => (
                      <option key={data.id_experiencie} value={data.id_experiencie}>
                        {data.enterprise_experiencie}
                      </option>
                    ))}
                  </select>
                )}
                name="experiencie"
              />
              {errors?.experiencie?.type === "required" ? <div>Required enterprise*</div> : <div></div>}
            </div>

            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <select multiple value={value} onChange={(e) => {
                    const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
                    onChange(selectedValues);
                  }}>
                    {titleFilter.map(dataFilter => {
                      const filteredOptions = filter.filter(a => a.titleFilter_id_filter === dataFilter.id_titlefilter);

                      return (
                        <optgroup key={dataFilter.id_titlefilter} label={dataFilter.title_titlefilter}>
                          {filteredOptions.length > 0 ? (
                            filteredOptions.map((data) => (
                              <option key={data.id_filter} value={data.id_filter}>
                                {data.work_filter}
                              </option>
                            ))
                          ) : (
                            <option disabled>No filters available</option>
                          )}
                        </optgroup>
                      );
                    })}

                  </select>
                )}
                name="filter"
              />
              {errors?.filter?.type === "required" ? <div>Required filter/s*</div> : <div></div>}
            </div>

            <input type="submit" value="Upload work" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ActFormModal = () => {
    return (
      <Modal show={actForm} onHide={handleCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>

            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image description</span>
                    <input
                      placeholder='Image description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required image description*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <select onChange={onChange} onBlur={onBlur} value={value} required>
                    <option value="">-- Select an enterprise --</option>
                    {enterprise.map(data => (
                      <option key={data.id_experiencie} value={data.id_experiencie}>
                        {data.enterprise_experiencie}
                      </option>
                    ))}
                  </select>
                )}
                name="experiencie"
              />
              {errors?.experiencie?.type === "required" ? <div>Required enterprise*</div> : <div></div>}
            </div>

            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <select multiple value={Array.isArray(value) ? value : []} onChange={(e) => {
                    const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
                    onChange(selectedValues);
                  }}>
                    {titleFilter.map(dataFilter => {
                      const filteredOptions = filter.filter(a => a.titleFilter_id_filter === dataFilter.id_titlefilter);

                      return (
                        <optgroup key={dataFilter.id_titlefilter} label={dataFilter.title_titlefilter}>
                          {filteredOptions.length > 0 ? (
                            filteredOptions.map((data) => (
                              <option key={data.id_filter} value={data.id_filter}>
                                {data.work_filter}
                              </option>
                            ))
                          ) : (
                            <option disabled>No filters available</option>
                          )}
                        </optgroup>
                      );
                    })}

                  </select>
                )}
                name="filter"
              />
              {errors?.filter?.type === "required" ? <div>Required filter/s*</div> : <div></div>}
            </div>
            <input type="submit" value="Update work" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <HeaderPanel />
      <div className='container-sm pt-5 pb-5'>
        <ContinueModal />
        <FailModal />
        <WorkModal />
        <PrevModal />
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleFormShow} target="_blank" className="btn-form">
              Upload new work
            </a>
          </div>
        </div>
        <FormModal />
        <ActFormModal />
        <h1 style={{ background: "grey", marginTop: "2em" }}>PREVIEW:</h1>
        <div className='d-flex justify-content-center align-content-center'>
          Uploaded video
        </div>
        <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} id='cards'>
          {arrayWorkItems()}
        </Row>
      </div>
    </>
  )
}
export default WorkPanel