import { URL_API } from "../constants";

export const getWork = async () => {
	try {
		const res = await fetch(URL_API + "/work", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const getOneWork = async (url) => {
	try {
		const res = await fetch(URL_API + "/onework/" + url, {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};


export const addImageWork = async (setImage) => {
	try {
		//console.log(setImage)
		const res = await fetch(URL_API + "/addwork", {
			method: 'POST',
			headers: {
				//Accept : '*/*',
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//"Content-Type": "application/json",
			},
			body: setImage
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

export const actWork = async (setActWork) => {
	try {
		//console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/actwork", {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actImageWork = async (setImage) => {
	try {
		//const res = 
		const res = await fetch(URL_API + "/actimgwork", {
			method: 'PUT',
			headers: {
				//Accept : '*/*',
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//"Content-Type": "application/json",
			},
			//body: JSON.stringify(newBazaar)
			body: setImage
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

export const deleteWork = async (url) => {
	try {
		const res = await fetch(URL_API + "/delwork/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

//work filter
export const getWorkFilter = async () => {
	try {
		const res = await fetch(URL_API + "/workfilter", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addWorkFilter = async (setActWork) => {
	try {
		//console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/addworkfilter", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}
}

export const actWorkFilter = async (url) => {
	try {
		//console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/actworkfilter/" + url, {
			method: "DELETE"
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}
}

//global
export const getComp = async () => {
	try {
		const res = await fetch(URL_API + "/comp", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const deleteImage = async (url) => {
	try {
		const res = await fetch(URL_API + "/delimage/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}