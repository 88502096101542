import { URL_API } from "../constants";

//Text
export const getAbout = async () => {
	try {
		const res = await fetch(URL_API + "/about", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addAbout = async (setWork) => {
	try {
		console.log(JSON.stringify(setWork))
		const res = await fetch(URL_API + "/addabout", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actAbout = async (url, setActWork) => {
	try {
		console.log(JSON.stringify(setActWork))
		const res = await fetch(URL_API + "/actabout/" + url, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delAboutText = async (url) => {
	try {
		const res = await fetch(URL_API + "/delabout/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

//Image
export const getImageAbout = async () => {
	try {
		const res = await fetch(URL_API + "/aboutimage", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addImageAbout = async (setImage) => {
	try {
		console.log(setImage);
		const res = await fetch(URL_API + "/addaboutimage/", {
			method: "POST",
			headers: {
				//Accept: "*/*",
				//'Content-Type': "multipart/form-data",
			},
			body: setImage
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actImageAbout = async (setActWork) => {
	try {
		const res = await fetch(URL_API + "/actaboutimage/", {
			method: "PUT",
			/*
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			*/
			body: setActWork
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actDescAbout = async (setActWork) => {
	try {
		const res = await fetch(URL_API + "/actaboutdesc/", {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActWork)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delAboutImage = async (url) => {
	try {
		const res = await fetch(URL_API + "/delaboutimage/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}