import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

//import * as fs from 'node:fs';
//const fs = require('fs')
//import listReactFiles from 'list-react-files'

import '../css/OneWork.css';
import Header from './Header.jsx';
import EmailModal from './EmailModal.jsx';
import Footer from './Footer.jsx';

import { compDB } from "./constants/CheckDatabse.jsx"
import { getOneWork } from './crud/work.jsx'
import { getOneExperience } from './crud/experiencies.jsx'

function OneWork() {

  const params = useParams();

  const [experiencies, setExperiencies] = useState([])
  const [work, setWork] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [title, setTitle] = useState('Error loading data (-‿-")')

  const navigate = useNavigate();

  const chargeWork = async () => {
    const compData = await compDB()
    if (compData === true) {
      let workData = await getOneWork(params.id)
      //console.log(workData)
      if (workData.length > 0) {
        //console.log(workData)
        workData.forEach(element => {
          //splice(element.image_work, 1, "../../archives/" + element.image_work)
          //console.log(element.image_work)
          element.image_work = "../archives/" + element.image_work
        });
        //console.log(workData)
        return setWork(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const chargeExperiencie = async () => {
    const compData = await compDB()
    if (compData === true) {
      let workData = await getOneExperience(params.id)
      //console.log(workData)
      if (workData.length > 0) {
        //console.log(workData)
        return setExperiencies(workData)
      }
    } else {
      setTitle('Error loading work (-‿-")')
      handleShow()
    }
  }

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }

  useEffect(() => {
    chargeWork()
    chargeExperiencie()
    componentFocus()
  }, [work !== undefined && location.pathname])

  const arrayDataItems = () =>
    work.map((data) => (
      <Col key={data.id_work}>
        <Card className='cardWork img-fluid'>
          <Card.Img variant='top' src={data.image_work} className='fotosWork img-fluid' alt={data.description_work} />
        </Card>
      </Col>
    ))

  const arrayDataText = () =>
    experiencies.map((data) => (
      <div>
        <h3 className='text-center'>Experiencie data</h3>
        <div className='textExperienciePage text-center'>Areas performed {data.areas_performed_experiencie}</div>
        <div className='textExperienciePage text-center'>Enterprise <Link to={data.link_experiencie} style={{ "color": "white" }} target="_blank">{data.enterprise_experiencie}</Link></div>
        <div className='textExperienciePage text-center'>Year {data.year_experiencie}</div>
        <div className='textExperienciePage text-center textParragraf'>Achievement {data.achievement_experiencie}</div>
      </div>
    ))

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          <section>
            <div className='d-flex justify-content-center align-content-center'>
              {arrayDataText()}
            </div>
            <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} className='cards'>
              {arrayDataItems()}
            </Row>
          </section>
        </div>
        <section>
          <EmailModal className='correo' />
        </section>
      </main>
      <Footer />
    </>
  )
}
export default OneWork