import { URL_API } from "../constants";

export const getExperience = async () => {
	try {
		const res = await fetch(URL_API + "/experiencie", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const getExperiencieWork = async () => {
	try {
		const res = await fetch(URL_API + "/experienciework", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const getOneExperience = async (url) => {
	try {
		const res = await fetch(URL_API + "/oneexperiencie/" + url, {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addExperiencie = async (setExperiencie) => {
	try {
		console.log(JSON.stringify(setExperiencie))
		const res = await fetch(URL_API + "/addexperiencie", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setExperiencie)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const addImageExperiencie = async (url, setImage) => {
	try {
		console.log("image" + setImage)
		//const res = 
		const res = await fetch(URL_API + "/addexperiencie/" + url, {
			method: 'POST',
			headers: {
				//Accept : '*/*',
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//"Content-Type": "application/json",
			},
			//body: JSON.stringify(newBazaar)
			body: setImage
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

export const actExperiencie = async (url, setActExperiencie) => {
	try {
		console.log(JSON.stringify(setActExperiencie))
		const res = await fetch(URL_API + "/actexperiencie/" + url, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setActExperiencie)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actImageExperiencie = async (url, setImage) => {
	try {
		//const res = 
		const res = await fetch(URL_API + "/actimgexperiencie/" + url, {
			method: 'PUT',
			headers: {
				//Accept : '*/*',
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//'Content-Type': "multipart/form-data; boundary=tomImage",
				//"Content-Type": "application/json",
			},
			//body: JSON.stringify(newBazaar)
			body: setImage
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

export const deleteExperiencie = async (url) => {
	try {
		const res = await fetch(URL_API + "/delexperiencie/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}