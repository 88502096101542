import React, { useState, useEffect } from 'react';
import Header from './Header';
import EmailModal from './EmailModal';
import Footer from './Footer';
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import '../css/About.css';

import { compDB } from "./constants/CheckDatabse.jsx"
import { getAbout, getImageAbout } from './crud/about';

const About = () => {
  const [text, setText] = useState([]);
  const [imageAbout, setImageAbout] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [title, setTitle] = useState('Error loading data (-‿-")')

  const navigate = useNavigate();

  const chargeAbout = async () => {
    const compData = await compDB()
    if (compData === true) {
    const aboutData = await getAbout()
    if (aboutData.length > 0) {
      return setText(aboutData)
    } else {
      return setText([
        { id_about: "100000", text_about: "No more texts available" }
      ])
    }
  } else {
    setTitle('Error loading text (-‿-")')
    handleShow()
  }
  }

  const chargeImageAbout = async () => {
    const compData = await compDB()
    if (compData === true) {
    const aboutData = await getImageAbout()
    if (aboutData.length > 0) {
      aboutData.forEach(element => {
        element.image_about = "../archives/" + element.image_about
      });
      return setImageAbout(aboutData)
    } else {
      return setImageAbout([
        { id_about: "100000", image_about: "../archives/no_image.png" }
      ])
    }
  } else {
    setTitle('Error loading image (-‿-")')
    handleShow()
  }

  }

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }

  useEffect(() => {
    chargeAbout()
    chargeImageAbout()
    componentFocus()
  }, [text !== undefined && location.pathname])

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          <section>
            {imageAbout !== undefined &&
              imageAbout.map((dataImage) => (
                <img
                  key={dataImage.id_image_about}
                  src={dataImage.image_about}
                  style={{ height: '25em', width: '100%', marginTop: "1em", marginBottom: "1em" }}
                  alt={dataImage.description_about}
                />
              ))
            }
          </section>
          <section>
            {text !== undefined &&
              text.map((data) => (
                <p className="text" key={data.id_about}>{data.text_about}</p>
              ))
            }
          </section>
        </div>
        <section>
          <EmailModal className='correo' />
        </section>
      </main>
      <Footer />
    </>
  )
}
export default About