import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import '../css/Experiencies.css';
import Header from './Header';
import EmailModal from './EmailModal';
import Footer from './Footer';

import { compDB } from "./constants/CheckDatabse.jsx"
import { getExperience } from './crud/experiencies';

const Experiencies = () => {

  const [experiencie, setExperiencie] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [title, setTitle] = useState('Error loading data (-‿-")')

  const navigate = useNavigate();

  const chargeExperiencie = async () => {
    const compData = await compDB()
    if (compData === true) {
      const dataExperiencie = await getExperience()
      if (dataExperiencie.length > 0) {
        dataExperiencie.forEach(element => {
          //splice(element.image_work, 1, "../../archives/" + element.image_work)
          //console.log(element.image_work)
          element.image_experiencie = "../archives/" + element.image_experiencie
        });
        //console.log(dataExperiencie)
        return setExperiencie(dataExperiencie)
      }
    } else {
      setTitle('Error loading experiencies (-‿-")')
      handleShow()
    }
  }

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }

  useEffect(() => {
    chargeExperiencie()
    componentFocus()
  }, [experiencie !== undefined && location.pathname])

  const arrayDataItems = () => {
    return (
      experiencie.length > 0 &&
      experiencie.map((data) => (
        <Col key={data.id_experiencie}>
          <Link to={'/enterprise/' + data.id_experiencie}>
            <Card className='cardExperiencies img-fluid'>
              <Card.Img variant='top' src={data.image_experiencie} className='fotosExperiencies img-fluid' alt={data.description_experiencie} />
            </Card>
          </Link>
        </Col>
      )
      ))
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          <section>
            <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} className='cards'>
              {arrayDataItems()}
            </Row>
          </section>
        </div>
        <section>
          <EmailModal className='correo' />
        </section>
      </main>
      <Footer />
    </>
  )
}
export default Experiencies