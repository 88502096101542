import React, { useState, useEffect } from 'react'
import HeaderPanel from './HeaderPanel'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form";
import Files from 'react-files'

import '../../css/About.css';
import { KEY_AS } from "../constants"

import { getUser } from "../crud/user"

import { compDB } from "../constants/CheckDatabse.jsx"
import { getAbout, addAbout, actAbout, delAboutText, getImageAbout, addImageAbout, actImageAbout, actDescAbout, delAboutImage } from "../crud/about"
import { deleteImage } from '../crud/work';

const About = () => {
  const [text, setText] = useState([]);
  const [imageAbout, setImageAbout] = useState([])
  const [user, setUser] = useState(null)
  const [form, setForm] = useState(false);
  const [actForm, setActForm] = useState(false);
  const [imageForm, setImageForm] = useState(false);
  const [actImageForm, setActImageForm] = useState(false);
  const [show, setShow] = useState(false);
  const [delAbout, setDelAbout] = useState(false)
  const [delImageAbout, setDelImageAbout] = useState(false)
  const [id, setId] = useState(undefined)
  const [file, setFile] = useState([])

  const [title, setTitle] = useState('Error loading data (-‿-")')
  //const [count, setCount] = useState(1)

  const navigate = useNavigate();

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const handleCloseAbout = () => setDelAbout(false);

  const handleCloseImageAbout = () => setDelImageAbout(false);

  const handleFormShow = () => setForm(true);
  const handleCloseForm = () => {
    reset()
    setForm(false)
  }

  const handleImageFormShow = () => setImageForm(true);
  const handleCloseImageForm = () => {
    reset()
    setImageForm(false)
  }

  const handleFormActShow = (id_about) => {
    let w = text.filter(a =>
      a.id_about === id_about
    )
    setValue("text", w[0].text_about)
    setId(id_about)
    setActForm(true);
  }

  const handleCloseActForm = () => {
    setActForm(false);
    reset()
  }

  const handleImageFormActShow = (id_image_about) => {
    let w = imageAbout.filter(a =>
      a.id_image_about === id_image_about
    )
    setFile({ "name": w[0].image_about.slice(12) })
    setValue("image", w[0].image_about.slice(12))
    setValue("description", w[0].description_about)
    setId(w[0].id_image_about)
    setActImageForm(true);
  }

  const handleImageCloseActForm = () => {
    setActImageForm(false);
    reset()
  }

  const handleAboutShow = (id_about) => {
    setDelAbout(true);
    setId(id_about)
    //deleteExperiencieFile(id_experiencie)
  }

  const handleImageAboutShow = (id_about, img) => {
    setDelImageAbout(true);
    setId([id_about, img])
    //deleteExperiencieFile(id_experiencie)
  }

  const CompleteForm = async (data) => {
    handleCloseForm()
    const compData = await compDB()
    if (compData) {
      let ArrayAbout = {}
      ArrayAbout = {
        text_about: data.text,
        user_id_user: user.id_user
      }
      //setCount(count + 1)
      const dataAbout = await addAbout(ArrayAbout)
      if (dataAbout !== undefined) {
        chargeAbout()
      }

    } else {
      setTitle('Error uploading text (-‿-")')
      handleShow()
    }

  }

  const ActForm = async (data) => {
    handleCloseActForm()
    const compData = await compDB()
    if (compData) {
      let ArrayAbout = {}
      ArrayAbout = {
        id_about: id,
        text_about: data.text
      }

      const dataActExperiencie = await actAbout(ArrayAbout.id_about, ArrayAbout)
      if (dataActExperiencie.affectedRows === 1) {
        chargeAbout()
      }

      setId(undefined)
    } else {
      setTitle('Error updating text (-‿-")')
      handleShow()
    }
  }

  const CompleteImageForm = async (data) => {
    handleCloseImageForm()
    const compData = await compDB()
    if (compData) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('description_about', data.description);
      formData.append('user_id_user', user.id_user);
      const resultImg = await addImageAbout(formData)
      if (resultImg !== null || resultImg !== undefined) {
        chargeImageAbout()
      }
    } else {
      setTitle('Error uploading image (-‿-")')
      handleShow()
    }


  }

  const ActImageForm = async (data) => {
    handleImageCloseActForm()
    const compData = await compDB()
    if (compData) {
      let ArrayAbout = [{}]
      ArrayAbout = [{
        id_image_about: id,
        description_about: data.description,
        image: file
      }]
      const y = imageAbout.filter(a =>
        a.id_image_about === ArrayAbout[0].id_image_about && a.image_about.slice(12) !== ArrayAbout[0].image.name
      )
      const z = imageAbout.filter(a =>
        a.id_image_about === ArrayAbout[0].id_image_about && a.description_about !== ArrayAbout[0].description
      )
      if (y.length > 0) {
        const formData = new FormData();
        formData.append('image', ArrayAbout[0].image);
        formData.append('id_image_about', ArrayAbout[0].id_image_about);
        const dataActImgWork = await actImageAbout(formData)
        //|| dataActImgWork !== null || dataActImgWork !== undefined

        if (dataActImgWork.affectedRows === 1) {
          chargeImageAbout()
        }
      }

      if (z.length > 0) {
        let ArrayAboutA = {}
        ArrayAboutA = {
          id_image_about: id,
          description_about: data.description,
        }
        const dataActImgWork = await actDescAbout(ArrayAboutA)
        //|| dataActImgWork !== null || dataActImgWork !== undefined

        if (dataActImgWork.affectedRows === 1) {
          chargeImageAbout()
        }
      }
    } else {
      setTitle('Error updating image (-‿-")')
      handleShow()
    }
  }

  const deleteAbout = async (id_about) => {
    handleCloseAbout()
    const compData = await compDB()
    if (compData) {
      const dataDeleteAbout = await delAboutText(id_about)

      setId(undefined)
      return chargeAbout()
    } else {
      setTitle('Error deleting text (-‿-")')
      handleShow()
    }
  }

  const deleteImageAbout = async (id_image_about) => {
    handleCloseImageAbout()
    const compData = await compDB()
    if (compData) {
      await deleteImage(id_image_about[1].slice(12))
      const dataDeleteAbout = await delAboutImage(id_image_about[0])

      setId(undefined)
      return chargeImageAbout()
    } else {
      setTitle('Error deleting image (-‿-")')
      handleShow()
    }
    //return chargeExperiencie()
  }

  const handleChange = (newFiles) => {
    setFile(...newFiles)
  }

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  const chargeAbout = async () => {
    const compData = await compDB()
    if (compData) {
      const aboutData = await getAbout()
      if (aboutData.length > 0) {
        return setText(aboutData)
      } else {
        return setText([
          { id_about: "100000", text_about: "There are no more texts left in the database" }
        ])
      }
    } else {
      setTitle('Error loading text (-‿-")')
      handleShow()
    }
  }

  const chargeImageAbout = async () => {
    const compData = await compDB()
    if (compData) {
      const aboutData = await getImageAbout()
      if (aboutData.length > 0) {
        aboutData.forEach(element => {
          element.image_about = "../archives/" + element.image_about
        });
        return setImageAbout(aboutData)
      } else {
        return setImageAbout([
          { id_about: "100000", image_about: "../archives/no_image.png" }
        ])
      }
    } else {
      setTitle('Error loading image (-‿-")')
      handleShow()
    }

  }

  const chargeUser = async () => {
    const userLocal = localStorage.getItem(KEY_AS); // Cambiar AsyncStorage por localStorage
    if (!userLocal) {
      return navigate("/admin/login");
    }
    const compData = await compDB();
    if (compData) {
      const userLocalJson = JSON.parse(userLocal); // Parsear el dato guardado en localStorage
      const userData = await getUser(userLocalJson.id_user);
      if (userData.length > 0) {
        return setUser(userData[0]);
      } else {
        navigate("/admin/login");
      }
    } else {
      setTitle('Error loading user (-‿-")');
      handleShow();
    }
  };

  useEffect(() => {
    chargeUser()
    chargeAbout()
    chargeImageAbout()
  }, [text !== undefined])

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      text: "",
      image: "",
      description: "",
    }
  });

  const ContinueModal = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const AboutModal = () => {
    return (
      <Modal show={delAbout} onHide={handleCloseAbout}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this text?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteAbout(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseAbout}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ImageAboutModal = () => {
    return (
      <Modal show={delImageAbout} onHide={handleCloseImageAbout}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this image?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteImageAbout(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseImageAbout}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const FormModal = () => {
    return (
      <Modal show={form} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteForm)}>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 16777215
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>About me</span>
                    <Form.Control
                      as="textarea"
                      rows={20}
                      placeholder='Text'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='textarea'
                      value={value}
                    />
                  </div>
                )}
                name="text"
              />
              {errors?.text?.type === "maxLength" ? <div>Only 16.777.215 characters are supported</div> : <div></div>}
              {errors?.text?.type === "required" ? <div>Required achievement*</div> : <div></div>}
            </div>

            <input type="submit" value="Upload about me" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ActFormModal = () => {
    return (
      <Modal show={actForm} onHide={handleCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActForm)}>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Logro</span>
                    <Form.Control
                      as="textarea"
                      rows={20}
                      placeholder='Texto'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='textarea'
                      value={value}
                    />
                  </div>
                )}
                name="text"
              />
              {errors?.text?.type === "maxLength" ? <div>Only 16.777.215 characters are supported</div> : <div></div>}
              {errors?.text?.type === "required" ? <div>Required achievement*</div> : <div></div>}
            </div>

            <input type="submit" value="Update about me" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ImageFormModal = () => {
    return (
      <Modal show={imageForm} onHide={handleCloseImageForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteImageForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Description</span>
                    <input
                      placeholder='Description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required description*</div> : <div></div>}
            </div>
            <input type="submit" value="Upload image" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseImageForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ImageFormActModal = () => {
    return (
      <Modal show={actImageForm} onHide={handleImageCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Completa los datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActImageForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Description</span>
                    <input
                      placeholder='Description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required description*</div> : <div></div>}
            </div>
            <input type="submit" value="Upload image" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleImageCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <HeaderPanel />
      <ContinueModal />
      <AboutModal />
      <FormModal />
      <ActFormModal />
      <ImageFormModal />
      <ImageFormActModal />
      <ImageAboutModal />
      <div className='container-sm pt-5 pb-5'>
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleFormShow} target="_blank" className="btn-form">
              Upload new about me
            </a>
          </div>
        </div>
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleImageFormShow} target="_blank" className="btn-form">
              Upload new image
            </a>
          </div>
        </div>
        <h1 style={{ background: "grey", marginTop: "2em" }}>PREVIEW:</h1>
        {imageAbout !== undefined &&
          imageAbout.map((dataImage) => (
            <>
              <div className='d-flex'>
                <div className="buton-delWork">
                  <div className="btn-delWork-float">
                    <a onClick={() => handleImageAboutShow(dataImage.id_image_about, dataImage.image_about)} target="_blank" className="btn-delWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="buton-editWork">
                  <div className="btn-editWork-float">
                    <a onClick={() => handleImageFormActShow(dataImage.id_image_about)} target="_blank" className="btn-editWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <img
                src={dataImage.image_about}
                style={{ height: '25em', width: '100%', marginTop: "1em", marginBottom: "1em" }}
              />
              <p className='d-flex justify-content-center align-content-center'>
                {"Description for blind: " + dataImage.description_about}
              </p>
            </>
          ))
        }

        {text !== undefined &&
          text.map((data) => (
            <>
              <div className='d-flex'>
                <div className="buton-delWork">
                  <div className="btn-delWork-float">
                    <a onClick={() => handleAboutShow(data.id_about)} target="_blank" className="btn-delWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="buton-editWork">
                  <div className="btn-editWork-float">
                    <a onClick={() => handleFormActShow(data.id_about)} target="_blank" className="btn-editWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <p className="text" key={data.id_about}>{data.text_about}</p>
            </>
          ))
        }
      </div>
    </>
  )
}
export default About