import React, { useState, useEffect } from 'react'
import HeaderPanel from './HeaderPanel.jsx'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form";
import Files from 'react-files'

import { KEY_AS } from "../constants/index.jsx"

import { compDB } from "../constants/CheckDatabse.jsx"
import { getUser } from "../crud/user.jsx"
import { getFilter, addFilter, actFilter, delFilter, getTitleFilter, addTitleFilter, actTitleFilter, delTitleFilter } from "../crud/filter.jsx"
//import * as fs from 'node:fs';
//const fs = require('fs')
//import listReactFiles from 'list-react-files'

const FilterPanel = () => {
  const [filter, setFilter] = useState([]);
  const [titleFilter, setTitleFilter] = useState([]);
  const [user, setUser] = useState(null)
  const [form, setForm] = useState(false);
  const [actForm, setActForm] = useState(false);
  const [titleForm, setTitleForm] = useState(false);
  const [actTitleForm, setActTitleFilterForm] = useState(false);
  const [show, setShow] = useState(false);
  const [showF, setShowF] = useState(false);
  const [delCFilter, setDelCFilter] = useState(false)
  const [delFilterTitle, setDelFilterTitle] = useState(false)
  const [id, setId] = useState(undefined)
  const [title, setTitle] = useState('Error loading data (-‿-")')
  //const [count, setCount] = useState(1)

  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShowF = () => setShowF(true);
  const handleCloseF = () => setShowF(false);

  const handleCloseFilter = () => setDelCFilter(false);

  const handleCloseTitleFilter = () => setDelFilterTitle(false);

  const handleFormShow = () => {
    chargeTitleFilter()
    setForm(true)
  }

  const handleCloseForm = () => {
    reset()
    setForm(false)
  }

  const handleTitleFormShow = () => setTitleForm(true);
  const handleCloseTitleForm = () => {
    reset()
    setTitleForm(false)
  }

  const handleFormActShow = (id_filter, titleFilter_id_filter) => {
    let w = filter.filter(a =>
      a.id_filter === id_filter
    )
    let x = titleFilter.filter(a =>
      a.id_titlefilter === titleFilter_id_filter
    )
    //agregar forma de cargar value title filter
    setValue("title_filter", x[0].id_titlefilter)
    setValue("filter", w[0].work_filter)
    setId(id_filter)
    setActForm(true);
  }

  const handleCloseActForm = () => {
    setActForm(false);
    reset()
  }

  const handleTitleFilterFormActShow = (id_titlefilter) => {
    let w = titleFilter.filter(a =>
      a.id_titlefilter === id_titlefilter
    )
    setValue("title_filter", w[0].title_titlefilter)
    setId(w[0].id_titlefilter)
    setActTitleFilterForm(true);
  }

  const handleTitleCloseActForm = () => {
    setActTitleFilterForm(false);
    reset()
  }

  const handleTitleFilterShow = (id_image_softSkill) => {
    setDelFilterTitle(true);
    setId(id_image_softSkill)
    //deleteExperiencieFile(id_experiencie)
  }

  const handleFilterShow = (id_filter) => {
    setDelCFilter(true);
    setId(id_filter)
  }

  const CompleteForm = async (data) => {
    handleCloseForm()
    const compData = await compDB()
    if (compData) {
      let ArrayFilter = {}
      ArrayFilter = {
        work_filter: data.filter,
        titleFilter_id_filter: data.title_filter
      }
      const dataFilter = await addFilter(ArrayFilter)
      if (dataFilter !== undefined) {
        chargeFilter()
      }
    } else {
      setTitle('Error loading filter (-‿-")')
      handleShow()
    }
  }

  const ActForm = async (data) => {
    handleCloseActForm()
    const compData = await compDB()
    if (compData) {
      let ArrayFilter = {}
      ArrayFilter = {
        id_filter: id,
        work_filter: data.filter,
        titleFilter_id_filter: data.title_filter
      }

      const dataActFilter = await actFilter(ArrayFilter.id_filter, ArrayFilter)
      if (dataActFilter.affectedRows === 1) {
        //setExperiencie([])
        chargeFilter()
      }

      setId(undefined)
    } else {
      setTitle('Error updating filter (-‿-")')
      handleShow()
    }
  }

  const CompleteTitleForm = async (data) => {
    handleCloseTitleForm()
    const compData = await compDB()
    if (compData) {
      let ArrayTitleFilter = {}
      ArrayTitleFilter = {
        title_titlefilter: data.title_filter
      }
      //console.log(ArrayTitleFilter)

      const resultImg = await addTitleFilter(ArrayTitleFilter)
      if (resultImg !== null || resultImg !== undefined) {
        chargeTitleFilter()
      }

    } else {
      setTitle('Error uploading title filter (-‿-")')
      handleShow()
    }
  }

  const ActTitleFilterForm = async (data) => {
    handleTitleCloseActForm()
    const compData = await compDB()
    if (compData) {
      let ArrayTitleFilter = [{}]
      ArrayTitleFilter = [{
        id_titlefilter: id,
        title_titlefilter: data.title_filter
      }]

      const z = titleFilter.filter(a =>
        a.id_titlefilter === ArrayTitleFilter[0].id_titlefilter
      )

      if (z.length > 0) {
        const dataActImgWork = await actTitleFilter(ArrayTitleFilter)
        //|| dataActImgWork !== null || dataActImgWork !== undefined

        if (dataActImgWork.affectedRows === 1) {
          chargeTitleFilter()

        }
      }
    } else {
      setTitle('Error updating title filter (-‿-")')
      handleShow()
    }
  }

  const deleteFilter = async (id_filter) => {
    handleCloseFilter()
    const compData = await compDB()
    if (compData) {
      const dataDeleteFilter = await delFilter(id_filter)
      if (dataDeleteFilter.affectedRows === 1) {
        setId(undefined)
        return chargeFilter()
      }
    } else {
      setTitle('Error deleting filter (-‿-")')
      handleShow()
    }
  }

  const deleteTitleFilter = async (id_titlefilter) => {
    handleCloseTitleFilter()
    const compData = await compDB()
    if (compData) {
      let z = filter.filter(a => a.titleFilter_id_filter === id_titlefilter)
      if (z.length === 0) {
        const dataDeleteTitleFilter = await delTitleFilter(id_titlefilter)
        if (dataDeleteTitleFilter.affectedRows === 1) {
          setId(undefined)
          return chargeTitleFilter()
          //return chargeExperiencie()
        }
      } else {
        handleShowF()
      }
    } else {
      setTitle('Error deleting title filter (-‿-")')
      handleShow()
    }
  }

  const chargeTitleFilter = async () => {
    const compData = await compDB()
    if (compData) {
      const titleFilterData = await getTitleFilter()
      if (titleFilterData.length > 0) {
        return setTitleFilter(titleFilterData)
      }

    } else {
      setTitle('Error loading title filter (-‿-")')
      handleShow()
    }
  }

  const chargeFilter = async () => {
    const compData = await compDB()
    if (compData) {

      const filterData = await getFilter()
      if (filterData.length > 0) {
        return setFilter(filterData)
      }

    } else {
      setTitle('Error loading filter (-‿-")')
      handleShow()
    }
  }

  const chargeUser = async () => {
    const userLocal = localStorage.getItem(KEY_AS); // Cambiar AsyncStorage por localStorage
    if (!userLocal) {
      return navigate("/admin/login");
    }
    const compData = await compDB();
    if (compData) {
      const userLocalJson = JSON.parse(userLocal); // Parsear el dato guardado en localStorage
      const userData = await getUser(userLocalJson.id_user);
      if (userData.length > 0) {
        return setUser(userData[0]);
      } else {
        navigate("/admin/login");
      }
    } else {
      setTitle('Error loading user (-‿-")');
      handleShow();
    }
  };

  useEffect(() => {
    chargeUser()
    chargeTitleFilter()
    chargeFilter()
  }, [user !== undefined])

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      filter: "",
      title_filter: "",
    }
  });

  const ContinueModal = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ContinueFilterModal = () => {
    return (
      <Modal show={showF} onHide={handleCloseF}>
        <Modal.Header closeButton>
          <Modal.Title>Clear all filters before clearing the title</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseF}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const FilterModal = () => {
    return (
      <Modal show={delCFilter} onHide={handleCloseFilter}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this filter?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteFilter(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseFilter}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const TitleFilterModal = () => {
    return (
      <Modal show={delFilterTitle} onHide={handleCloseTitleFilter}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this title filter?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteTitleFilter(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseTitleFilter}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const FormModal = () => {
    return (
      <Modal show={form} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteForm)}>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <select onChange={onChange} onBlur={onBlur} value={value} required>
                      <option value="">-- Select a title for filter --</option>
                      {titleFilter.map(data => (
                        <option key={data.id_titlefilter} value={data.id_titlefilter}>
                          {data.title_titlefilter}
                        </option>
                      ))}
                    </select>
                  )
                }}
                name="title_filter"
              />
              {errors?.title_filter?.type === "required" ? <div>Required title filter*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 200
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Filter</span>
                    <input
                      placeholder="Filter"
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="filter"
              />
              {errors?.filter?.type === "maxLength" ? <div>Only 200 characters are supported</div> : <div></div>}
              {errors?.filter?.type === "required" ? <div>Required achievement*</div> : <div></div>}
            </div>

            <input type="submit" value="Uplodad filter" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ActFormModal = () => {
    return (
      <Modal show={actForm} onHide={handleCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActForm)}>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <select onChange={onChange} onBlur={onBlur} value={value} required>
                      <option value="">-- Select a title for filter --</option>
                      {titleFilter.map(data => (
                        <option key={data.id_titlefilter} value={data.id_titlefilter}>
                          {data.title_titlefilter}
                        </option>
                      ))}
                    </select>
                  );
                }}
                name="title_filter"
              />
              {errors?.title_filter?.type === "required" ? <div>Required title filter*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 200
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Filter</span>
                    <input
                      placeholder="Filter"
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="filter"
              />
              {errors?.filter?.type === "maxLength" ? <div>Only 200 characters are supported</div> : <div></div>}
              {errors?.filter?.type === "required" ? <div>Required achievement*</div> : <div></div>}
            </div>


            <input type="submit" value="Update filter" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const TitleFormModal = () => {
    return (
      <Modal show={titleForm} onHide={handleCloseTitleForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteTitleForm)}>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 45
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Title filter</span>
                    <input
                      placeholder="Title filter"
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="title_filter"
              />
              {errors?.title_filter?.type === "maxLength" ? <div>Only 45 characters are supported</div> : <div></div>}
              {errors?.title_filter?.type === "required" ? <div>Required title filter*</div> : <div></div>}
            </div>

            <input type="submit" value="Upload title filter" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseTitleForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const TitleFormActModal = () => {
    return (
      <Modal show={actTitleForm} onHide={handleTitleCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActTitleFilterForm)}>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 45
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Title filter</span>
                    <input
                      placeholder="Title filter"
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="title_filter"
              />
              {errors?.title_filter?.type === "maxLength" ? <div>Only 45 characters are supported</div> : <div></div>}
              {errors?.title_filter?.type === "required" ? <div>Required title filter*</div> : <div></div>}
            </div>
            <input type="submit" value="Update image" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleTitleCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <HeaderPanel />
      <ContinueModal />
      <FilterModal />
      <FormModal />
      <ActFormModal />
      <TitleFormModal />
      <TitleFormActModal />
      <TitleFilterModal />
      <ContinueFilterModal/>
      <div className='container-sm pt-5 pb-5'>
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleFormShow} target="_blank" className="btn-form">
              Uplaod new filter
            </a>
          </div>
        </div>

        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleTitleFormShow} target="_blank" className="btn-form">
              Upload new title
            </a>
          </div>
        </div>
        <h1 style={{ background: "grey", marginTop: "2em" }}>PREVIEW:</h1>
        {titleFilter !== undefined &&
          titleFilter.map((dataTitleFilter) => (
            <>
              <div className='d-flex'>
                <div className="buton-delWork">
                  <div className="btn-delWork-float">
                    <a onClick={() => handleTitleFilterShow(dataTitleFilter.id_titlefilter)} target="_blank" className="btn-delWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="buton-editWork">
                  <div className="btn-editWork-float">
                    <a onClick={() => handleTitleFilterFormActShow(dataTitleFilter.id_titlefilter)} target="_blank" className="btn-editWork">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <p className='text' key={dataTitleFilter.id_titlefilter}>{dataTitleFilter.title_titlefilter + ":"}</p>
              {filter !== undefined &&
                filter.filter(a => a.titleFilter_id_filter === dataTitleFilter.id_titlefilter).map((data) => (
                  <>
                    <div className='d-flex'>
                      <div className="buton-delWork">
                        <div className="btn-delWork-float">
                          <a onClick={() => handleFilterShow(data.id_filter)} target="_blank" className="btn-delWork">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                      <div className="buton-editWork">
                        <div className="btn-editWork-float">
                          <a onClick={() => handleFormActShow(data.id_filter, data.titleFilter_id_filter)} target="_blank" className="btn-editWork">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <p className='text' key={data.id_filter}>&nbsp;&nbsp;&nbsp;&nbsp;{data.work_filter}</p>
                  </>
                ))
              }
            </>
          ))
        }

      </div>
    </>
  )
}
export default FilterPanel