import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import Files from 'react-files'
import { useForm, Controller } from "react-hook-form";
import { Link } from 'react-router-dom';

import { KEY_AS } from "../constants"

import { getUser } from "../crud/user"

import '../../css/Experiencies.css';

import HeaderPanel from './HeaderPanel';

import { compDB } from "../constants/CheckDatabse.jsx"
import { getExperience, addExperiencie, addImageExperiencie, actExperiencie, actImageExperiencie, deleteExperiencie } from '../crud/experiencies';
import { deleteImage } from '../crud/work';

const Experiencies = () => {
  const [user, setUser] = useState(null)
  //const [files, setFiles] = useState([])
  const [file, setFile] = useState([])
  const [show, setShow] = useState(false);
  const [form, setForm] = useState(false);
  const [actForm, setActForm] = useState(false);
  const [formExperiencie, setFormExperiencie] = useState([]);
  const [experiencie, setExperiencie] = useState([]);
  const [newExperiencie, setNewExperiencie] = useState([]);
  const [count, setCount] = useState(1)
  const [fail, setFail] = useState(false)
  const [delExperiencie, setDelExperiencie] = useState(false)
  const [prev, setPrev] = useState(false)
  const [allPrev, setAllPrev] = useState(false)
  const [title, setTitle] = useState('Error loading data (-‿-")')

  const [id, setId] = useState(undefined)

  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShowPrev = () => setPrev(true);
  const handleClosePrev = () => setPrev(false);

  const handleFormShow = () => setForm(true);
  const handleCloseForm = () => {
    setFile([])
    reset()
    setForm(false)
  }

  const handleFormActShow = (id_experiencie) => {
    let w = experiencie.filter(a =>
      a.id_experiencie === id_experiencie
    )
    if (w.length <= 0) {
      let x = newExperiencie.filter(a =>
        a.id_experiencie === id_experiencie
      )
      if (x.length > 0) {
        //x.image_experiencie = file.name
        //console.log([x])
        setFormExperiencie([x])
        setFile(x[0].image)
        setValue("image", x[0].image.name)
        setValue("areas_performed", x[0].areas_performed_experiencie)
        setValue("enterprise", x[0].enterprise_experiencie)
        setValue("link", x[0].link_experiencie)
        setValue("year", x[0].year_experiencie)
        setValue("work_description", x[0].work_description_experiencie)
        setValue("achievement", x[0].achievement_experiencie)
        setValue("description", x[0].description_experiencie)
      }
    } else {
      setFormExperiencie([w])
      setFile({ "name": w[0].image_experiencie.slice(12) })
      setValue("image", w[0].image_experiencie.slice(12))
      setValue("areas_performed", w[0].areas_performed_experiencie)
      setValue("enterprise", w[0].enterprise_experiencie)
      setValue("link", w[0].link_experiencie)
      setValue("year", w[0].year_experiencie)
      setValue("work_description", w[0].work_description_experiencie)
      setValue("achievement", w[0].achievement_experiencie)
      setValue("description", w[0].description_experiencie)
    }
    setActForm(true);
  }
  const handleCloseActForm = () => {
    setActForm(false);
    setFormExperiencie([]);
    setFile([])
    reset()
    //console.log(experiencie)
  }

  const handleFailShow = () => setFail(true);

  const handleAllPrevShow = () => setAllPrev(true);
  const handleCloseAllPrev = () => setAllPrev(false);

  //let a = new Array()

  const handleChange = (newFiles) => {
    //console.log(newFiles[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g,""))
    //newFiles.name = newFiles.name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
    // const renamedFiles = newFiles.map((file) => {
    //   Crear un nuevo nombre sin acentos
    //   const newName = file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    
    //   Crear un nuevo archivo con el nuevo nombre y las propiedades originales
    //   const newFile = new File([file], newName, { 
    //     type: file.type, 
    //     lastModified: file.lastModified
    //   });
    
    //   Retornar el nuevo archivo con la propiedad `name` actualizada
    //   return {
    //     ...file,
    //     name: newFile.name,  // Sobrescribir solo el `name` con el nuevo nombre
    //   };
    // });

    setFile(...newFiles);
    //console.log("Archivos renombrados:", renamedFiles);
    //let prueba = newFiles
    //prueba[0].name = prueba[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
    //console.log(newFiles)
    //setFile(...newFiles)
    //a.push({ id: count, name: newFiles.name })
    //count = count + 1
    //console.log(a)
    //console.log(count)
  }

  const CompleteForm = async (data) => {
    handleCloseForm()
    const compData = await compDB()
    if (compData) {
      //console.log(file)
      //formData.append(file.id, new Blob([file], { type: file.type }), file.name || 'file')
      let ArrayExperiencie = {}
      ArrayExperiencie = {
        id_experiencie: count,
        image: file,
        areas_performed_experiencie: data.areas_performed,
        enterprise_experiencie: data.enterprise,
        link_experiencie: data.link,
        year_experiencie: data.year,
        work_description_experiencie: data.work_description,
        achievement_experiencie: data.achievement,
        description_experiencie: data.description,
        user_id_user: user.id_user
      }
      setCount(count + 1)
      setNewExperiencie(prevExperiencie => [...prevExperiencie, ArrayExperiencie])

      //setFiles(prevFiles => [...prevFiles, ...file])
      //console.log(experiencie)
    } else {
      setTitle('Error uploading experiencie (-‿-")')
      handleShow()
    }
  }

  const ActForm = async (data) => {
    handleCloseActForm()
    const compData = await compDB()
    if (compData) {
      //console.log(data)
      //console.log(formExperiencie[0][0].id_experiencie)
      //formData.append(file.id, new Blob([file], { type: file.type }), file.name || 'file')
      let ArrayExperiencie = {}
      ArrayExperiencie = {
        id_experiencie: formExperiencie[0][0].id_experiencie,
        image: file,
        areas_performed_experiencie: data.areas_performed,
        enterprise_experiencie: data.enterprise,
        link_experiencie: data.link,
        year_experiencie: data.year,
        work_description_experiencie: data.work_description,
        achievement_experiencie: data.achievement,
        description_experiencie: data.description,
        user_id_user: user.id_user
      }
      //setFormExperiencie([])
      //console.log(experiencie)
      let w = experiencie.filter(a =>
        a.id_experiencie === ArrayExperiencie.id_experiencie
      )
      if (w.length <= 0) {
        let x = newExperiencie.filter(a =>
          a.id_experiencie === ArrayExperiencie.id_experiencie
        )
        if (x.length > 0) {

          setNewExperiencie(
            newExperiencie.filter(a =>
              a.id_experiencie !== ArrayExperiencie.id_experiencie
            )
          )
          setNewExperiencie(prevExperiencie => [...prevExperiencie, ArrayExperiencie])
        }
      } else {
        /*
        const y = experiencie.filter(a =>
          a.id_experiencie !== ArrayExperiencie.id_experiencie
        )
        setExperiencie(y)
        //setExperiencie(prevExperiencie => [...prevExperiencie, ArrayExperiencie])
        //setExperiencie([experiencie, ArrayExperiencie])
        await prueba(ArrayExperiencie)
        */
        const dataActExperiencie = await actExperiencie(ArrayExperiencie.id_experiencie, ArrayExperiencie)
        if (dataActExperiencie.affectedRows === 1) {
          //setExperiencie([])
          chargeExperiencie()
        }
        const y = experiencie.filter(a =>
          a.id_experiencie === ArrayExperiencie.id_experiencie && a.image_experiencie.slice(12) !== ArrayExperiencie.image.name
        )
        //console.log(y)
        if (y.length > 0) {
          const formData = new FormData();
          formData.append('image', ArrayExperiencie.image);
          const dataActImgExperiencie = await actImageExperiencie(ArrayExperiencie.id_experiencie, formData)
          //|| dataActImgExperiencie !== null || dataActImgExperiencie !== undefined

          if (dataActImgExperiencie.affectedRows === 1) {
            chargeExperiencie()
          }
        }
        //setExperiencie([])
        //chargeExperiencie()

      }

    } else {
      setTitle('Error updating experiencie (-‿-")')
      handleShow()
    }
  }

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  const onSubmit = async () => {
    const compData = await compDB()
    if (compData) {
      newExperiencie.forEach(async (dataExperiencie) => {
        const result = await addExperiencie(dataExperiencie)
        //console.log(result)
        if (result === null || result === undefined) {
          handleFailShow()
        } else {
          //console.log(result)
          //handleShow()
          //.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
          const formData = new FormData();
          //formData.append(dataExperiencie.image.id, new Blob([dataExperiencie.image], { type: dataExperiencie.image.type }), dataExperiencie.image.name || 'file')
          //formData.append(dataExperiencie.image.id, new Blob([dataExperiencie.image], { type: dataExperiencie.image.type }), dataExperiencie.image.name || 'image')
          formData.append('image', dataExperiencie.image);
          //formData.append('experiencie_id_experiencie', result.insertId);
          //console.log(formData)
          const resultImg = await addImageExperiencie(result.insertId, formData)
          if (resultImg !== null || resultImg !== undefined) {
            setNewExperiencie([])
            chargeExperiencie()
          }
          //console.log(resultImg)
        }
      })
    } else {
      setTitle('Error uploading experiencie (-‿-")')
      handleShow()
    }
  }

  const handleExperiencieShow = (id_experiencie, img) => {
    setDelExperiencie(true);
    setId([id_experiencie, img])
    //deleteExperiencieFile(id_experiencie)
  }
  const handleCloseExperiencie = () => setDelExperiencie(false);

  const handlePrevShow = (id_experiencie) => {
    setPrev(true);
    setId(id_experiencie)
  }

  const deleteExperiencieFile = async (id_experiencie) => {
    handleCloseExperiencie()
    const compData = await compDB()
    if (compData) {
      setExperiencie(
        experiencie.filter(a =>
          a.id_experiencie !== id_experiencie
        )
      )

      deleteImage(id_experiencie[1].slice(12))
      const dataDeleteExperiencie = await deleteExperiencie(id_experiencie[0])
      //console.log(dataDeleteExperiencie)
      setId(undefined)
      //return chargeExperiencie()
    } else {
      setTitle('Error deleting experiencie (-‿-")')
      handleShow()
    }
  }

  const deletePrevFile = (id_experiencie) => {
    setNewExperiencie(
      newExperiencie.filter(a =>
        a.id_experiencie !== id_experiencie
      )
    )
    handleClosePrev()
    setId(undefined)
  }

  const deleteAll = () => {
    setNewExperiencie([])
    handleCloseAllPrev();

  }

  const chargeExperiencie = async () => {
    const compData = await compDB()
    if (compData) {
      const dataExperiencie = await getExperience()
      if (dataExperiencie.length > 0) {
        dataExperiencie.forEach(element => {
          //splice(element.image_work, 1, "../archives/s" + element.image_work)
          //console.log(element.image_work)
          element.image_experiencie = "../archives/" + element.image_experiencie
        });
        return setExperiencie(dataExperiencie)
      }
    } else {
      setTitle('Error loading experiencie (-‿-")')
      handleShow()
    }
  }

  const chargeUser = async () => {
    const userLocal = localStorage.getItem(KEY_AS); // Cambiar AsyncStorage por localStorage
    if (!userLocal) {
      return navigate("/admin/login");
    }
    const compData = await compDB();
    if (compData) {
      const userLocalJson = JSON.parse(userLocal); // Parsear el dato guardado en localStorage
      const userData = await getUser(userLocalJson.id_user);
      if (userData.length > 0) {
        return setUser(userData[0]);
      } else {
        navigate("/admin/login");
      }
    } else {
      setTitle('Error loading user (-‿-")');
      handleShow();
    }
  };

  useEffect(() => {
    chargeUser()
    chargeExperiencie()
  }, [user !== undefined])

  const arrayDataItems = () =>
    experiencie.length > 0 &&
    experiencie.map((data) => (
      <Col key={data.id_experiencie} style={{ marginBottom: "5em" }}>
        <Card className='cardPanelWork img-fluid'>
          <Card.Header>
            <div className='d-flex'>
              <div className="buton-delWork">
                <div className="btn-delWork-float">
                  <a onClick={() => handleExperiencieShow(data.id_experiencie, data.image_experiencie)} target="_blank" className="btn-delWork">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="buton-editWork">
                <div className="btn-editWork-float">
                  <a onClick={() => handleFormActShow(data.id_experiencie)} target="_blank" className="btn-editWork">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </Card.Header>
          <div className='d-flex justify-content-center align-content-center align-items-center border' id='card-col'>
            <Card.Img variant='top' src={data.image_experiencie} className='fotosPanelWork img-fluid' />
          </div>
          <h3 style={{ color: "black" }}>Experiencie data:</h3>
          <div className='textExperiencie'>Areas performed: {data.areas_performed_experiencie}</div>
          <div className='textExperiencie'>Enterprise: {data.enterprise_experiencie}</div>
          <div className='textExperiencie'>Enterprise link:&nbsp;<Link to={data.link_experiencie} style={{ "color": "black" }}>{data.link_experiencie}</Link></div>
          <div className='textExperiencie'>Year: {data.year_experiencie}</div>
          <div className='textExperiencie'>Achievement: {data.achievement_experiencie}</div>
          <div className='textExperiencie'>Description for blind: {data.description_experiencie}</div>
        </Card>
      </Col>
    ))

  const arrayNewDataItems = () =>
    newExperiencie.length > 0 &&
    newExperiencie.map((data) => (
      <Col key={data.id_experiencie} style={{ marginBottom: "5em" }}>
        <Card className='cardPanelWork img-fluid'>
          <Card.Header>
            <div className='d-flex'>
              <div className="buton-delWork">
                <div className="btn-delWork-float">
                  <a onClick={() => handlePrevShow(data.id_experiencie)} target="_blank" className="btn-delWork">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8.738 7.78l-3.891 3.977c-1.17 1.196-1.755 1.794-1.834 2.523q-.025.232 0 .464c.08.73.664 1.327 1.834 2.523l.149.152c.624.639.937.958 1.31 1.172q.328.188.689.291c.413.118.856.118 1.743.118c.886 0 1.33 0 1.743-.118q.361-.104.688-.29c.374-.215.686-.534 1.31-1.173l2.845-2.907M8.738 7.78l3.582-3.657C13.706 2.708 14.4 2 15.263 2s1.556.708 2.941 2.125l.743.76C20.316 6.283 21 6.983 21 7.85s-.685 1.566-2.054 2.964l-3.622 3.698M8.738 7.78l6.586 6.732M10 22h11" color="currentColor"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="buton-editWork">
                <div className="btn-editWork-float">
                  <a onClick={() => handleFormActShow(data.id_experiencie)} target="_blank" className="btn-editWork">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20h9M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854zM15 5l3 3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </Card.Header>
          <div className='d-flex justify-content-center align-content-center align-items-center border' id='card-col'>
            <Card.Img variant='top' src={data.image.preview.url} className='fotosPanelWork img-fluid' />
          </div>
          <h3>Experiencie data:</h3>
          <div className='textExperiencie'>Areas performed: {data.areas_performed_experiencie}</div>
          <div className='textExperiencie'>Enterprise: {data.enterprise_experiencie}</div>
          <div className='textExperiencie'>Enterprise link:&nbsp;{data.link_experiencie}</div>
          <div className='textExperiencie'>Year: {data.year_experiencie}</div>
          <div className='textExperiencie'>Achievement: {data.achievement_experiencie}</div>
          <div className='textExperiencie'>Description for blind: {data.description_experiencie}</div>
        </Card>
      </Col>
    ))

  const ContinueModal = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const FailModal = () => {
    return (
      <Modal show={fail} onHide={() => navigate("/admin/experiencie")}>
        <Modal.Header closeButton>
          <Modal.Title>Ha fallado la subida, lo siento, vuelve a intentarlo</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => navigate("/admin/experiencie")}>
            {"Vale :("}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ExperiencieModal = () => {
    return (
      <Modal show={delExperiencie} onHide={handleCloseExperiencie}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this experiencie?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deleteExperiencieFile(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseExperiencie}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const PrevModal = () => {
    return (
      <Modal show={prev} onHide={handleClosePrev}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete this experiencie?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => deletePrevFile(id)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClosePrev}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const PrevAllModal = () => {
    return (
      <Modal show={allPrev} onHide={handleCloseAllPrev}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete the entire previous list?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={deleteAll}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseAllPrev}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      image: "",
      areas_performed: "",
      enterprise: "",
      link: "",
      year: "",
      work_description: "",
      achievement: "",
      description: "",
    }
  });

  const FormModal = () => {
    return (
      <Modal show={form} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Fill in the data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(CompleteForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required image*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image description for blinds</span>
                    <input
                      placeholder='Description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required description*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 200
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Areas Performed</span>
                    <input
                      placeholder='Areas Performed'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="areas_performed"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 200 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required areas performed*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 200
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Enterprise</span>
                    <input
                      placeholder='Enterprise'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="enterprise"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 200 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required enterprise*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 2084
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Enterprise link</span>
                    <input
                      placeholder='Enterprise link'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="link"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 2.084 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required enterprise link*</div> : <div></div>}
            </div>

            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 45
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Year</span>
                    <input
                      placeholder='Experiencie year'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="year"
              />
              {errors?.year?.type === "maxLength" ? <div>Only 45 characters are supported</div> : <div></div>}
              {errors?.year?.type === "required" ? <div>Required year*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 2000
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Work description</span>
                    <input
                      placeholder='Work description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="work_description"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 2.000 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required work description*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 2000
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Achievement</span>
                    <input
                      placeholder='Achievement'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="achievement"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 2.000 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required achievement*</div> : <div></div>}
            </div>
            <input type="submit" value="Upload experiencie" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ActFormModal = () => {
    return (
      <Modal show={actForm} onHide={handleCloseActForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(ActForm)}>
            <div>
              <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={handleChange}
                  onError={handleError}
                  accepts={['image/*']}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                  style={{ height: '100px', width: '100%', color: "white" }}>
                  {isDragging => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: isDragging ? 'green' : 'black',
                        border: isDragging ? '2px dashed #00c3ff' : '2px dashed #D3D3D3'
                      }}>
                      {isDragging && 'Drop me (ﾉ◕ヮ◕)ﾉ*:・ﾟ✧'}
                      {!isDragging && 'Drop the file here (you can also click) ༼ つ ◕_◕ ༽つ'}
                    </div>
                  )}
                </Files>
              </div>
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image name</span>
                    <input
                      placeholder="Image name (info only)"
                      onBlur={onBlur}
                      onChange={onChange && setValue("image", file.name)}
                      type='text'
                      value={file ? value = file.name : ""}
                      disabled
                    />
                  </div>
                )}
                name="image"
              />
              {errors?.image?.type === "required" && file.name === undefined ? <div>Required imagen*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 500
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Image description for blinds</span>
                    <input
                      placeholder='Description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="description"
              />
              {errors?.description?.type === "maxLength" ? <div>Only 500 characters are supported</div> : <div></div>}
              {errors?.description?.type === "required" ? <div>Required description*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 200
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Areas Performed</span>
                    <input
                      placeholder='Areas Performed'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="areas_performed"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 200 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required areas performed*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 200
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Enterprise</span>
                    <input
                      placeholder='Enterprise'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="enterprise"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 200 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required enterprise*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 2084
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Enterprise link</span>
                    <input
                      placeholder='Enterprise link'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="link"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 2.084 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required enterprise link*</div> : <div></div>}
            </div>

            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 45
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Year</span>
                    <input
                      placeholder='Experiencie year'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="year"
              />
              {errors?.year?.type === "maxLength" ? <div>Only 45 characters are supported</div> : <div></div>}
              {errors?.year?.type === "required" ? <div>Required year*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 2000
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Work description</span>
                    <input
                      placeholder='Work description'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="work_description"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 2.000 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required work description*</div> : <div></div>}
            </div>
            <div className="controller">
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 2000
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <span>Achievement</span>
                    <input
                      placeholder='Achievement'
                      onBlur={onBlur}
                      onChange={onChange}
                      type='text'
                      value={value}
                    />
                  </div>
                )}
                name="achievement"
              />
              {errors?.link?.type === "maxLength" ? <div>Only 2.000 characters are supported</div> : <div></div>}
              {errors?.link?.type === "required" ? <div>Required achievement*</div> : <div></div>}
            </div>
            <input type="submit" value="Actualizar experiencia" style={{ marginTop: "2em" }} />
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleCloseActForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <HeaderPanel />
      <div className='container-sm pt-5 pb-5'>
        <ContinueModal />
        <FailModal />
        <ExperiencieModal />
        <PrevModal />
        <PrevAllModal />
        <div className="buton-form">
          <div className="btn-form-float">
            <a onClick={handleFormShow} target="_blank" className="btn-form">
              Upload new experiencie
            </a>
          </div>
        </div>
        <FormModal />
        <ActFormModal />
        <div className='d-flex'>
          <div className="buton-workPanel">
            <div className="btn-workPanel-float">
              <a onClick={onSubmit} target="_blank" className="btn-workPanel">
                Save data
              </a>
            </div>
          </div>
          <div className="buton-workPanel">
            <div className="btn-workPanel-float">
              <a onClick={handleAllPrevShow} target="_blank" className="btn-workPanel">
                Clear preview
              </a>
            </div>
          </div>
        </div>
        <h1 style={{ background: "grey", marginTop: "2em" }}>PREVIEW:</h1>
        <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} className='cards'>
          {arrayDataItems()}
        </Row>
        <h1 style={{ background: "grey", marginTop: "2em" }}>NEW EXPERIENCIES:</h1>
        <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} className='cards'>
          {arrayNewDataItems()}
        </Row>
      </div>
    </>
  )
}
export default Experiencies