import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";

import { KEY_AS } from "../constants"

import { getUser } from "../crud/user"
import { compDB } from "../constants/CheckDatabse"

import '../../css/Header.css'

//<img src="/img.jpg" alt="" width="50" height="50" className="img-fluid img-thumbnail" />

const HeaderPanel = () => {
  const [show, setShow] = useState(false);
  const [showComp, setShowComp] = useState(false);
  const handleCloseComp = () => setShowComp(false);
  const handleShowComp = () => setShowComp(true);


  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleCloseSession = async () => {
    localStorage.removeItem(KEY_AS)
    navigate("/admin/login")
  }
  const handleShow = () => setShow(true);

  const [user, setUser] = useState(undefined)

  const chargeUser = async () => {
    const userLocal = localStorage.getItem(KEY_AS)
    if (!userLocal) {
      navigate("/admin/login")
    }
    const compData = await compDB()
    if (compData) {
      const userLocalJson = JSON.parse(userLocal)
      //const userAsyncjson = userAsync
      //console.log(userAsyncjson)
      const userData = await getUser(userLocalJson.id_user)
      if (userData.length > 0) {
        return setUser(userData[0])
      } else {
        return undefined
      }
    } else {
      handleShowComp()
    }
  }

  useEffect(() => {
    var pathname = window.location.pathname,
      pages = ['about', 'work', 'filter', 'experiencies', 'softskills', 'video'];

    $('.nav-link').each(function (i) {
      if (pathname.includes(pages[i - 1])) $(this).addClass('active');
      else if (this.className.includes('active')) $(this).removeClass('active');
    });

    chargeUser()
  }, [user !== undefined])

  return (
    <>
      <Modal show={showComp} onHide={handleCloseComp}>
        <Modal.Header closeButton>
          <Modal.Title>Error loading user (-‿-")</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleCloseComp}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>

      <Navbar bg="dark" sticky='top'>
        <Container>
          {user && <Link className='navbar-brand nav-link' onClick={handleShow}>{"Welcome " + user.username_user}</Link>}
          <Nav className="w-100">
            <Link className="nav-link" to={'/admin/about'}>About</Link>
            <Link className="nav-link active" to={'/admin/work'}>Work</Link>
            <Link className="nav-link" to={'/admin/filter'}>Filters</Link>
            <Link className="nav-link" to={'/admin/experiencies'}>Experiencies</Link>
            <Link className="nav-link" to={'/admin/softskills'}>Soft Skills</Link>
            <Link className="nav-link" to={'/admin/video'}>Video</Link>
          </Nav>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Want to log out?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSession}>
            Yes, log out
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Do not log out
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default HeaderPanel