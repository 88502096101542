import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from "react-player"

//import * as fs from 'node:fs';
//const fs = require('fs')
//import listReactFiles from 'list-react-files'

import '../css/Work.css';
import { getWork } from './crud/work';
import { getVideo } from './crud/video';
import { compDB } from "./constants/CheckDatabse.jsx"
import { getFilteringWork } from './crud/filter.jsx';
import Header from './Header';
import EmailModal from './EmailModal';
import Footer from './Footer';

import { FiltersContext } from './FiltersContext';

const Work = () => {
  const [name, setName] = useState([]);
  const [work, setWork] = useState([])
  const [filterWork, setFilterWork] = useState([])
  const [img, setImg] = useState([{ id: "100000", img_video: "../archives/no_image.png" }])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //const { selectedFilters } = useContext(FiltersContext);

  const [title, setTitle] = useState('Error loading data (-‿-")')

  const { selectedFilters, setSelectedFilters } = useContext(FiltersContext);
  const removeFilter = (filterId) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((id) => id !== filterId));
  };

  const removeAllFilter = () => {
    setSelectedFilters([]);
  };

  const navigate = useNavigate();

  const chargeVideo = async () => {
    const compData = await compDB()
    if (compData === true) {
      let videoData = await getVideo()
      //console.log(videoData)
      if (videoData.length > 0) {
        videoData.forEach(element => {
          element.name_video = "../archives/" + element.name_video
        });
        return setName(videoData)
      } else {
        return setName([
          { id_video: "100001", name_video: "../archives/default.mp4" }
        ])
      }
    } else {
      setTitle('Error loading video (-‿-")')
      handleShow()
    }
  }

  const chargeWork = async () => {
    const compData = await compDB()
    if (compData === true) {
      let workData = await getWork()
      if (workData.length > 0) {
        //console.log(workData)
        workData.forEach(element => {
          //splice(element.image_work, 1, "../../archives/" + element.image_work)
          //console.log(element.image_work)
          element.image_work = "../archives/" + element.image_work
        });
        //console.log(workData)
        return setWork(workData)
      }
    } else {
      setTitle('Error loading works (-‿-")')
      handleShow()
    }
  }

  const chargeFilterWork = async () => {
    const compData = await compDB()
    if (compData === true) {
      let workData = await getFilteringWork()
      if (workData.length > 0) {
        return setFilterWork(workData)
      }
    } else {
      setTitle('Error loading works (-‿-")')
      handleShow()
    }
  }

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }

  useEffect(() => {
    chargeWork()
    chargeVideo()
    chargeFilterWork()
    componentFocus()
  }, [work !== undefined && location.pathname])


  const arrayDataItems = () =>
    work.map((data) => (
      <Col key={data.id_work}>
        <Link to={'/enterprise/' + data.experiencie_id_experiencie}>
          <Card className='cardWork img-fluid'>
            <Card.Img variant='top' src={data.image_work} className='fotosWork img-fluid' alt={data.description_work} />
          </Card>
        </Link>
      </Col>
    ))

  const arrayDataFilteredItems = () =>
    work.map((data) => (
      selectedFilters.map((filter) => (
        filterWork.filter(a => a.work_id_work === data.id_work && a.work_filter === filter).map((dataFilter) =>
          <Col key={`work-${data.id_work}-filter-${filter.id_filter}-dataFilter-${dataFilter}`}>
            <Link to={'/enterprise/' + data.experiencie_id_experiencie}>
              <Card className='cardWork img-fluid'>
                <Card.Img variant='top' src={data.image_work} className='fotosWork img-fluid' alt={data.description_work} />
              </Card>
            </Link>
          </Col>
        )
      ))
    ))

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate(0)}>
            Recharge
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Okay :(
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          <section>
            {selectedFilters.length === 0 &&
              name !== undefined &&
              name.map((data) => (
                <div className="react-player-wrapper">
                  <ReactPlayer
                    key={data.id_video}
                    url={data.name_video}
                    width='100%'
                    height='100%'
                    className='img-fluid react-player'
                    controls={true}
                    loop
                    playing={true}
                    alt={data.description_video}
                    light={img[0].img_video}
                  />
                </div>
              ))
            }
          </section>
          <section>
            {selectedFilters.length === 0 ? (
              <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} className='cards'>
                {arrayDataItems()}
              </Row>
            ) : (
              <>
                <Row xs={1} sm={2} md={4} lg={6} xl={6} xxl={6}>
                  <div className="btn-filters-float">
                    <a onClick={() => removeAllFilter()} target="_blank" className="btn-filters" tabIndex="0">
                      Clear all filters
                    </a>
                  </div>
                  {selectedFilters.map((filter) => (
                    <div className="btn-filters-float" key={filter}>
                      <a onClick={() => removeFilter(filter)} target="_blank" className="btn-filters" tabIndex="0">
                        {`Clear filter ${filter}`}
                      </a>
                    </div>
                  ))}
                </Row>
                {/* Elementos filtrados */}
                <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={3} className='cards'>
                  {arrayDataFilteredItems()}
                </Row>
              </>
            )
            }
          </section>
        </div>
        <section>
          <EmailModal className='correo' />
        </section>
      </main >
      <Footer />
    </>
  )
}
export default Work