import { URL_API } from "../constants";

export const getUser = async (url) => {
  try {
    const res = await fetch(URL_API + "/us/" + url, {
      method: "GET",
    });

    return await res.json();
  } catch (error) {
    console.error(error);
  }
};

export const LogInUser = async (setUser) => {
	/*
	fetch("http://localhost:3300/loggin", {

		method: "POST",
		body: JSON.stringify(dataLoggin),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Headers': '*'
		}
	})
		.then(
			res => {
				(res.json()
					.then((j) => {
						j = j[0];
						console.log(j)
						cookies.set('id', j.id_user, { path: "/", sameSite: "strict" })
						cookies.set('user', j.username, { path: "/", sameSite: "strict" })
						cookies.set('email', j.email, { path: "/", sameSite: "strict" })
					}))
				if (res.status === 200) {
					//window.location.href="./home"
				}
			}
		)
		.then()
		.catch(console.log())
*/
	try {
		const res = await fetch( URL_API + "/us/loggin", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setUser)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}