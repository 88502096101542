// FiltroContext.js
import React, { createContext, useState } from 'react';

export const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  return (
    <FiltersContext.Provider value={{ selectedFilters, setSelectedFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};
