import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Header from './Header';
import Footer from './Footer';

function Redirect() {

  const navigate = useNavigate();

  const a = () => { return navigate("/work"); }

  const location = useLocation()

  const componentFocus = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) {
      mainContent.focus();
    }
  }


  useEffect(() => {
    componentFocus()
    a()
  }, [location.pathname])

  return (
    <>
      <Header />
      <main id="main">
        <div className='container-sm pt-5 pb-5'>
          Loading...
        </div>
      </main>
      <Footer />
    </>
  );
}
export default Redirect