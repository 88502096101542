import { URL_API } from "../constants";

export const getVideo = async () => {
	try {
		const res = await fetch(URL_API + "/video", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};


export const addVideo = async (setVideo) => {
	try {
		console.log(setVideo);
		const res = await fetch(URL_API + "/addvideo/", {
			//signal: AbortSignal.timeout(10000),
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(setVideo)
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actVideo = async (setVideo) => {
	try {
		const res = await fetch(URL_API + "/actvideo/", {
			method: "PUT",
			
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			
			body: JSON.stringify(setVideo)

		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actDescVideo = async (setVideo) => {
	try {
		const res = await fetch(URL_API + "/actdescvideo/", {
			method: "PUT",
			
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			
			body: JSON.stringify(setVideo)

		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delVideoText = async (url) => {
	try {
		const res = await fetch(URL_API + "/delvideo/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}

//Image
export const getImageVideo = async () => {
	try {
		const res = await fetch(URL_API + "/videoimage", {
			method: "GET",
		});

		return await res.json();
	} catch (error) {
		console.error(error);
	}
};

export const addImageVideo = async (setImage) => {
	try {
		console.log(setImage);
		const res = await fetch(URL_API + "/addvideoimage/", {
			method: "POST",
			headers: {
				//Accept: "*/*",
				//'Content-Type': "multipart/form-data",
			},
			body: setImage
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const actImageVideo = async (setActWork) => {
	try {
		const res = await fetch(URL_API + "/actvideoimage", {
			method: "PUT",
			/*
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			*/
			body: setActWork
		});
		return await res.json();
	} catch (error) {
		console.error(error);
	}

}

export const delVideoImage = async (url) => {
	try {
		const res = await fetch(URL_API + "/delvideoimage/" + url, {
			method: 'DELETE',
		});

		return await res.json();

	} catch (error) {
		console.error(error);
	}
}